import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiquidacionService } from 'src/app/services/liquidacion.service';
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'

declare var $: any;


@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
})
export class SolicitudComponent implements OnInit {
  titulo = "Solicitud de información";
  requiredType="2"
  solicitudesObj:any = {IdSector: 0,IdEntidad: 0,Anio: "", Trimestre: ""};
  objMes1:any=[];
  objMes2:any=[];
  objMes3:any=[];
  monthName=['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  stepsNames=['','Paso 1 - Cálculo de la Base de Cobro','Paso 2 - Calificación trimestral']
  objComentarios:any;
  coordinates: string[];
  periodo: string[];
  paso: any;
  nameMes1: string;
  nameMes2: string;
  nameMes3: string;
  namePaso: string;

  constructor( private sessionService:SessionService, private liquidacionService:LiquidacionService,private router:Router, private activatedRoute:ActivatedRoute){}
  
  ngOnInit(){
    this.paso = this.activatedRoute.snapshot.params.paso;
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    this.periodo = window.localStorage.getItem('periodo').split('-');
    this.coordinates = window.localStorage.getItem('coordinates').split(';');
    this.solicitudesObj.IdSector=Number(this.coordinates[0]);
    this.solicitudesObj.IdEntidad=Number(this.coordinates[1]);
    this.solicitudesObj.Anio=this.periodo[1];
    this.solicitudesObj.Trimestre=this.periodo[0];
    this.solicitudesObj.paso=this.paso;
    this.namePaso = this.stepsNames[this.paso];
    this.liquidacionService.getComentariosByPaso(this.solicitudesObj).subscribe(
      response=>{
        this.objComentarios=response;
        this.objMes1 = this.objComentarios.meses[0].cuentas;
        this.objMes2 = this.objComentarios.meses[1].cuentas;
        this.objMes3 = this.objComentarios.meses[2].cuentas;
        this.nameMes1 = this.monthName[Number(this.objComentarios.meses[0].mes)]
        this.nameMes2 = this.monthName[Number(this.objComentarios.meses[1].mes)]
        this.nameMes3 = this.monthName[Number(this.objComentarios.meses[2].mes)] 
      },
      error=>console.error(error)
    )
  } 
  enviaComentarios(){
    let comentarios = $(".content-comment").html();
    this.solicitudesObj.comentarios = comentarios;
    
    this.liquidacionService.sendComentarios(this.solicitudesObj).subscribe(
      response=>{
        if (response){
          Swal.fire({
            text:  "Su información a sido enviada a Fogafin. Pronto lo contactaremos.",
            icon: 'success'
          });
          if (this.paso==1) {
            if (this.solicitudesObj.IdSector==28) {
              this.router.navigate(['/basecobrosedpe']);
            } else {
              this.router.navigate(['/basecobro']);
            }
          } else {
            this.router.navigate(['/calificacion']);
          }
        }else{
          Swal.fire({
            text:  "No se enviaron los comentarios",
            icon: 'info'
          });
        }
      },
      error=>console.error(error)
    ) 
    
  }
}
