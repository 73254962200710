import { Component, OnInit } from '@angular/core';
import { LiquidacionService } from '../../services/liquidacion.service'
import { GlobalsConstants } from '../../commons/global-constants';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-reporte-cliente',
    templateUrl: './reporte-cliente.component.html'
})
export class ReporteClienteComponent implements OnInit {
    titulo = "Resultado consulta hist\u00f3rico";
    requiredType = '1'

    objReport: any = {
        trimestreInicial: '',
        trimestreFinal: '',
        anioInicial: '',
        anioFinal: '',
        idSector: '',
        idEntidad: ''
    }
    report: any = [];

    idSector = window.localStorage.getItem('sectorEntidad');
    idEntidad = window.localStorage.getItem('idEntidad');

    anioInicial = this.activatedRoute.snapshot.params["anioInicial"];
    trimestreInicial = this.activatedRoute.snapshot.params["trimestreInicial"];
    anioFinal = this.activatedRoute.snapshot.params["anioFinal"];
    trimestreFinal = this.activatedRoute.snapshot.params["trimestreFinal"];

    ReportClienteObj: any = {
        idSector: this.idSector,
        idEntidad: this.idEntidad,
        anioInicial: this.anioInicial,
        trimestreInicial: this.trimestreInicial,
        anioFinal: this.anioFinal,
        trimestreFinal: this.trimestreFinal
    };


    constructor(private liquidacionService: LiquidacionService, private activatedRoute: ActivatedRoute, private globals: GlobalsConstants) { }

    ngOnInit() {
        if (this.idSector == '28') $(".oculta").css("display", "none");
        this.liquidacionService.getReportCliente(this.ReportClienteObj).subscribe(res => {
            this.report = res; //console.log(res);
            if (res == '') {
                Swal.fire({html: `<b>No hay informaci&#243;n para el rango consultado`, icon: 'error'
                }).then((result) => {
                    if (result.isConfirmed) {
                        let reportUrl = `/Home`;
                        window.location.href = reportUrl;
                    }
                });
            }
        });
    }

    fileExportar() {
        this.liquidacionService.fileExportarCliente(this.ReportClienteObj).subscribe(
            //this.liquidacionService.fileExportarCliente(this.ReportClienteObj, this.idSector).subscribe(
            response => {
                let status: any = { estado: false, detalle: "" }
                status = response;
                let fileAddress = this.globals.SERVER_address + status.detalle;
                window.open(fileAddress, '_blank');
            }
        )
    }

}
