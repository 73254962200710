import { Component, OnInit, Input } from '@angular/core';
import { FechasService } from '../../services/fechas.service';
import { LiquidacionService } from '../../services/liquidacion.service'
import { GlobalsConstants } from '../../commons/global-constants';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-fechas-client',
    templateUrl: './fechas-client.component.html',
})
export class FechasClientComponent implements OnInit {
    fecha: any = { anioInicial: '', anioFinal: '', trimestreInicial: '', trimestreFinal: '', tipo: '' };

    //fechasLista: FechasClientes[] = [];
    fechasLista: any = [];
    aniosTimestreLista: any = [];
    aniosFiltroLista: any = [];
    aniosTimestreFiltroLista: any = [];
    fechasListaPd: any = [];
    aniosTimestreListaPd: any = [];

    coordinates = window.localStorage.getItem('coordinates').split(';');
    idSector = window.localStorage.getItem('sectorEntidad');
    idEntidad = window.localStorage.getItem('idEntidad');
    nombreEntidad = window.localStorage.getItem('nombreEntidad');
    opcion = 1;

    ReportClienteObj: any = { IdSector: Number(this.idSector), IdEntidad: Number(this.idEntidad), };
    //fechasList: FechasClientes = new FechasClientes;
    constructor(private fechaService: FechasService, private liquidacionService: LiquidacionService, private globals: GlobalsConstants, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        //this.listarAniosData();
        this.listarAniosDataPd();
        //this.listarAniosDataCert();
        $("input[type='radio']").click(function () {
            //var fecha = new Date();
            //var ano = fecha.getFullYear();

            $('select').val("clear");
            var tipo = $(this).val();
            if (tipo == 'historico') {
                $("#historico").attr('hidden', false);
                $("#trimestre").attr('hidden', true);
                $("#anio").attr('hidden', true);
                this.opcion = 1;
            }
            if (tipo == 'trimestre') {
                $("#historico").attr('hidden', true);
                $("#trimestre").attr('hidden', false);
                $("#anio").attr('hidden', true);
                this.opcion = 2;
            }
            if (tipo == 'anio') {
                $("#historico").attr('hidden', true);
                $("#trimestre").attr('hidden', true);
                $("#anio").attr('hidden', false);
                this.opcion = 3;
            }
        });
    }
    listarAniosData() {
        //this.fechaService.listarAnios().subscribe((data: any) => { this.fechasLista = data; console.log(data);});
        $('#trimestreInicial').val('clear');
        $('#anioInicial').val('clear');
        $('#trimestreFinal').val('clear');
        this.fechaService.listarAnios(this.idSector, 1).subscribe(data => { this.fechasLista = data; });
    }
    listarAniosDataCert() {
        //this.fechaService.listarAnios().subscribe((data: any) => { this.fechasLista = data; console.log(data);});
        $('#anioPSD').val('clear');
        this.fechaService.listarAnios(this.idSector, 3).subscribe(data => { this.fechasLista = data; });
    }
    // ok para opcion 1
    ListarTrimestreAnioData() {
        $('#trimestreInicial').val('clear');
        $('#trimestreFinal').val('clear');
        if ($('#anioInicial').val() !== 'clear') {
            this.aniosFiltroLista = [];
            this.aniosTimestreFiltroLista = [];
            this.fechaService.CargarTrimestreAnioInicial($('#anioInicial').val(), this.idSector).subscribe(datau => { this.aniosTimestreLista = datau; });

            this.ListarTrimestreAnioFiltroData();
        }
    }
    // ok lista los a�os finales conforme a la seleccion del a�o inicial
    listarAniosFiltro() {
        $('#trimestreFinal').val('clear');
        this.ListarTrimestreAnioFiltroData();
        this.fechaService.listarAniosFiltro($('#anioInicial').val()).subscribe(datad => { this.aniosFiltroLista = datad; });
    }
    // ok lista los trimestres teniendo en cuenta los datos anteriormente seleccionados, como a�o y trimestre inicial y a�o final
    ListarTrimestreAnioFiltroData() {
        if ($('#anioFinal').val() !== 'clear') {
            this.aniosTimestreFiltroLista = [];
            this.fechaService.ListarTrimestreAnioFiltro($('#anioInicial').val(), $('#trimestreInicial').val(), $('#anioFinal').val()).subscribe(datat => { this.aniosTimestreFiltroLista = datat; });
        }
    }
    //******************** Opcion 2
    listarAniosDataPd() {
        //this.fechaService.listarAnios().subscribe((data: any) => { this.fechasLista = data; console.log(data);});
        this.fechaService.listarAnios(this.idSector, 2).subscribe(data => { this.fechasListaPd = data; });
    }
    ListarTrimestreAnioDataPd() {
        if ($('#anioInicialPd').val() !== 'clear') this.fechaService.CargarTrimestreAnioInicial($('#anioInicialPda').val(), this.idSector).subscribe(datau => { this.aniosTimestreListaPd = datau; });
    }
    //**********************************************************************************
    decodificarEntidadesHTMLNumericas(texto) {
        return texto.replace(/^([a-zA-Z0-9\s������������]+)$/g, function (m, ascii) {
            return String.fromCharCode(ascii);
        });
    }

    onSubmit() {
        var tipo = $('input:radio[name=consulta]:checked').val();
        //ok
        if (tipo == 'historico') {

            if ($('#trimestreFinal').val() === 'clear' || $('#trimestreFinal').val() === null) Swal.fire({ text: "No ha definido el rango de fechas correctas.", icon: 'error' });
            else {
                let reportUrl = `/reporte-cliente/${$('#anioInicial').val()}/${$('#trimestreInicial').val()}/${$('#anioFinal').val()}/${$('#trimestreFinal').val()}`;
                window.location.href = reportUrl;
            }
        }
        //ok
        if (tipo == 'trimestre') {
            let reportUrl = `/liquidacion-client/${$('#anioInicialPda').val()}/${$('#trimestreInicialPd').val()}`
            window.location.href = reportUrl;
        }
        //ok
        if (tipo == 'anio') {
            var pdfAddress = this.globals.SERVER_address
            this.ReportClienteObj.Anio = $('#anioPSD').val();

            if ($('#anioPSD').val() === 'clear') Swal.fire({ text: "No ha seleccionado el a\u00f1o a consultar.", icon: 'error' });
            else {
                //this.liquidacionService.getPDFDescargarCartaPagoSeguroDepositoCliente(this.idSector, this.idEntidad, anio, this.nombreEntidad).subscribe(
                //this.liquidacionService.getPDFDescargarCartaPagoSeguroDepositoCliente(this.ReportClienteObj).subscribe(


                let reportUrl = `/pagosAnuales/${$('#anioPSD').val()}`;
                window.location.href = reportUrl;


                //this.liquidacionService.getPDF(this.ReportClienteObj, this.nombreEntidad).subscribe(
                //    response => {
                //        let pdfAddress = this.globals.SERVER_address + response
                //        window.open(pdfAddress, '_blank');
                //        $('#enviar').modal('hide');
                //        $('#anioPSD').val('clear');
                //    }
                //)
            }
        }
    }

}

