import { Component, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service';
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service';

declare var $: any;

@Component({
    selector: 'app-basescobro',
    templateUrl: './basescobro.component.html',
})
export class BasesCobroComponent {
    titulo = "Paso 1 - Cálculo de la Base de Cobro";
    requiredType = "2"
    step = 1;
    nextStep = false;
    baseCobroReqObj: any = { IdSector: 0, IdEntidad: 0, Anio: "", Trimestre: "", };
    periodo = [];
    coordinates = [];
    objMes1: any;
    objMes2: any;
    objMes3: any;
    monthName = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    nameMes1: any;
    nameMes2: any;
    nameMes3: any;

    resp: any = [];
    respTramiteData: any = [];

    objBaseCobro: any = { anio: "", idEntidad: 0, idSector: 0, comtcompl: false, meses: [] }
    active: boolean = false;
    inactive: boolean = true;

    constructor(private sessionService: SessionService, private router: Router, private liquidacionService: LiquidacionService) { }

    ngOnInit() {
        $('#sendComment').attr('disabled', this.objBaseCobro.comtcompl)
        this.sessionService.verifySession()
        this.sessionService.verifyRequired(this.requiredType);
        this.periodo = window.localStorage.getItem('periodo').split('-');
        this.coordinates = window.localStorage.getItem('coordinates').split(';');
        this.baseCobroReqObj.IdSector = Number(this.coordinates[0]);
        this.baseCobroReqObj.IdEntidad = Number(this.coordinates[1]);
        this.baseCobroReqObj.Anio = this.periodo[1];
        this.baseCobroReqObj.Trimestre = this.periodo[0];
        if (this.baseCobroReqObj.IdSector == '28') this.router.navigate(['/home']);

        this.getDataBaseCobro();

        this.getDataBaseCobroTramiteData();

        this.liquidacionService.validarEstadoProceso(this.baseCobroReqObj).subscribe(
            response => {
                this.resp = response;
                //if (this.resp.paso == 3) {/*Bloquea si el proceso va en paso 3 liquidacion ok*/
                if (this.resp.radicado != null) {/*Bloquea si OnBase devuelve numero de radiado ok*/
                    //if (this.resp.estado == 1) {/*Boblea si el estado del proceso es=1 cuando las calificaciones qdan ok y se pasa a estado 3*/
                    $('a.action').addClass('d-none');
                    this.active = true;
                    this.inactive = false;
                }
                else $('a.action').removeClass('d-none')
            },
            error => { console.error(error) }
        )
        /*
      this.liquidacionService.validarEstadoProceso(this.baseCobroReqObj).subscribe(
        response=>{setTimeout(() => {let resp:any = true;
          resp=response
          this.active = resp;
          this.inactive = !this.active;
          if (this.active){
            $('a.action').addClass('d-none');
          }else{
            $('a.action').removeClass('d-none')
          }
        }, 750);
        },
        error=>{
          console.error(error)
        }
      )
      */
        $('[data-toggle="tooltip"]').tooltip('hide')

    }

    getDataBaseCobroTramiteData() {
        this.liquidacionService.getDataBaseCobroTramiteData(this.baseCobroReqObj).subscribe(
            response => {
                this.respTramiteData = response;
            }
        )
    }

    getDataBaseCobro() {
        this.liquidacionService.getDataBaseCobro(this.baseCobroReqObj).subscribe(
            response => {
                setTimeout(() => {
                    this.objBaseCobro = response;
                    this.objMes1 = this.objBaseCobro.meses[0].cuentas;
                    this.objMes2 = this.objBaseCobro.meses[1].cuentas;
                    this.objMes3 = this.objBaseCobro.meses[2].cuentas;
                    this.nameMes1 = this.monthName[Number(this.objBaseCobro.meses[0].mes)]
                    this.nameMes2 = this.monthName[Number(this.objBaseCobro.meses[1].mes)]
                    this.nameMes3 = this.monthName[Number(this.objBaseCobro.meses[2].mes)]
                    $('#sendComment').attr('disabled', !this.objBaseCobro.comtcompl)
                }, 500);
            },
            error => console.error(error)
        )
    }

    sendComment() {
        this.router.navigate(['/solicitudes/1']);
    }

    validateAccount(cuenta, indice) {
        let mes = Number(this.objBaseCobro.meses[indice].mes)
        this.baseCobroReqObj.mes = mes;
        this.baseCobroReqObj.Codigo = cuenta;
        let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
        this.liquidacionService.getValueFogafin(this.baseCobroReqObj).subscribe(
            response => {
                let objResponse: any;
                objResponse = response;
                this.objBaseCobro.meses[indice].cuentas[posCuenta].valor = Number($(`#${cuenta}_${indice}`).val());
                this.objBaseCobro.meses[indice].cuentas[posCuenta].estado = 1;
                this.objBaseCobro.meses[indice].cuentas[posCuenta].valorFogafin = objResponse.valor;
                this.objBaseCobro.meses[indice].cuentas[posCuenta].coincide = this.objBaseCobro.meses[indice].cuentas[posCuenta].valorFogafin == Number(this.objBaseCobro.meses[indice].cuentas[posCuenta].valor) ? true : false;
                this.objBaseCobro.meses[indice].cuentas[posCuenta].coincide ? this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario = "" : null;
            },
            error => console.error(error)
        )
        //console.log(this.objBaseCobro.meses[indice].cuentas[posCuenta])
        //console.log(this.objBaseCobro)
    }

    setComentario() {
        let cuenta = $('#mdl_comment').attr('data-cuenta');
        let indice = $('#mdl_comment').attr('data-indice');
        let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
        this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario = $(`#mdl_comment`).val();
        $('#comentario').modal('hide');
    }

    showCommentInput(cuenta, indice) {
        let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
        let comentario = this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario;
        $('#mdl_comment').val(comentario == "" ? "" : comentario)
        $('#mdl_comment').attr({ 'data-cuenta': cuenta, "data-indice": indice });
        $('#comentario').modal('show');

    }

    saveBaseCobro() {

        this.liquidacionService.saveDataBaseCobro(this.objBaseCobro).subscribe(
            response => {
                setTimeout(() => {
                    let objResponse: any = { estado: false };
                    objResponse = response;
                    if (objResponse.estado) {
                        Swal.fire({
                            text: "Datos guardados satisfactoriamente",
                            icon: 'success'
                        });
                        this.getDataBaseCobro()
                    } else {
                        Swal.fire({
                            text: "No se guardaron los datos",
                            icon: 'info'
                        });
                    }
                }, 500);
            },
            error => console.error(error)

        )
    }

    Validate() {
        this.router.navigate(['/calificacion']);
    }

    missingOneDecimalCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (x && x.length === 1) return true;
        return false;
    }

    missingAllDecimalsCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (!x) return true;
        return false;
    }

    formatoNumero(nStr) {

        let r: String = '';
        let x, x1, x2, rgx, y1, y2;
        rgx = /(\d+)(\d{3})/;

        if (nStr === '') return '';
        if (Number.isNaN(nStr)) return '';
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? ',' + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }

        /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
        if (x1.indexOf(',') !== -1) {
            y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

            y2 = x1.split(',');
            r = y2[0] + y1;
        } else {
            r = x1 + x2;
            if (this.missingOneDecimalCheck(r)) return r += '0';
            if (this.missingAllDecimalsCheck(r)) return r += ',00';
        }

        return r;
    }

}
