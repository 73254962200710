import { Component, OnInit, Input} from '@angular/core';
import {NgForm, PatternValidator} from '@angular/forms';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario'
import {UsuariosComponent} from '../usuarios/usuarios.component'
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'editar-usuario',
  templateUrl: './usuario-editar.component.html',
})
export class UsuarioEditarComponent implements OnInit{

  @Input() idUsuario: any = 0;
  @Input() usuario: any={
    id: 0,
    idSector:'',
    idEntidad:'',
    nombreContacto:'',
    email:'',
    telefono:'',
    celular:'',
    password:'',
    activo:false,
    bloqueado:false,
    administrador:false,
    consulta:false,
    restablecer:false,
  };
 idSectorTemp:'';
 idEntidadTemp:'';
 isAdmin:false;
  @Input() sectores:any;
  @Input() entidades:any;
  setType:any;
  constructor(private usuarioService:UsuariosService,private usuarios: UsuariosComponent){}

  ngOnInit(){
    this.usuario.password='';
  }

  verifyPassword(){
    let valid =$('#passwordEdit').val()!=''? this.charValidator(this.usuario.password):true;
    console.log(this.usuario.password)
      if (!valid){
        $('#messaje').removeClass('d-none')
        //$('#password').adclas('disabled',true)
      }else{
        $('#messaje').addClass('d-none')
        //$('#save').removeAttr('disabled')
      }

  }

  charValidator(password) {
    var res = false;
    if (password.match(/[a-z]/g) && password.match(/[A-Z]/g) && password.match(/[0-9]/g) && password.match(/[^a-zA-Z\d]/g) && password.length >= 8){
      res = true;
    }
    return res;
  }

  entidadChange(){
  if (!this.usuario.administrador)
  {
    let entTemp = this.entidades.find(entidad => entidad.codigo === this.usuario.idEntidad);
    this.usuario.nombreEntidad = entTemp.nombre;
  }
  else
  {
    this.usuario.idSector='';
    this.usuario.idEntidad='';
    this.usuario.nombreEntidad = '';
    this.usuario.nombreSector = '';
  }
  }
  sectorChange(){
    if (!this.usuario.administrador)
    {
    this.usuarioService.getEntidades(this.usuario.idSector, "false").subscribe(
      response=>{
        this.usuario.idEntidad='';
        this.entidades = response;
        let entTemp = this.entidades.find(entidad => entidad.codigo === this.usuario.idEntidad);
        this.usuario.nombreEntidad = entTemp.nombre;
      },
      err=>console.error(err)
    )
    }
    else
    {
      this.usuario.idSector='';
      this.usuario.idEntidad='';
      this.usuario.nombreEntidad = '';
      this.usuario.nombreSector = '';
    }
  }

  passGenerator(){
    let checkStatus = $("#restablecer").prop('checked');
    if (checkStatus){
      this.usuarioService.getPassword().subscribe(
        response=>{
          let password:any={password:''};
          password = response
          this.usuario.password=password.password;
        },
        err=>console.error(err)
      )
    }else{
      this.usuario.password=''
    }
  }

  usrAdm()
  {
    this.usuario.idSector='';
    this.usuario.idEntidad='';
    this.usuario.nombreEntidad = '';
    this.usuario.nombreSector = '';
    if (this.usuario.administrador)
    {
      $("#sector").prop('disabled', 'disabled');
      $("#entidad").prop('disabled', 'disabled');
    } else {
      $("#sector").removeAttr('disabled');
      $("#entidad").removeAttr('disabled');
    }
  }

  onSubmit(usuario) {
    let valida = true;
    if (!this.usuario.administrador)
    {
      if (this.usuario.idEntidad=="" ||  this.usuario.idEntidad==0){
        valida = false;
      }
      if (this.usuario.idSector=="" || this.usuario.idSector==0){
        valida = false;
      }
      if (!valida)
      {
        Swal.fire({
          html: `<p style="text-align:justify">Datos incompletos. Verifique los datos ingresados</p>`,
          width: '400px'
        });
      }
    }
    else
    {
      this.usuario.idSector='';
      this.usuario.idEntidad='';
      this.usuario.nombreEntidad = '';
      this.usuario.nombreSector = '';
    }
    if (valida){
      delete usuario.fechaCreacion;
      delete usuario.fechaModificacion;
      Swal.fire({
        html:'<b>Confirmación de edición de usuario</b><br>Confirma la edición del usuario?' ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, guardar',
        cancelButtonText: 'Cancelar'


      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.usuario.administrador)
          usuario.nombreSector =  '';
          usuario.nombreEntidad = this.usuario.nombreEntidad;
          usuario.idEntidad = usuario.idEntidad!=""? usuario.idEntidad:0;
          usuario.idSector =  usuario.idSector!=""? usuario.idSector:0;
          usuario.bloqueado =  usuario.bloqueado==null?false:usuario.bloqueado
          usuario.activo =  usuario.activo==null?false:usuario.activo
          usuario.consulta =  usuario.consulta==null?false:usuario.consulta
          usuario.restablecer =  usuario.restablecer==null?false:usuario.restablecer
          usuario.administrador =  usuario.administrador==null?false:usuario.administrador
          //console.log(usuario)
          this.usuarioService.updateUsuario(usuario).subscribe(
            response=>{
              //console.log(response)
              this.usuarios.getUsuarios()
              $('.check').prop('checked', false);
              $('#editar').attr('disabled',true)
              $('#cancelar').attr('disabled',true).addClass('d-none')
              $('#eliminar').attr('disabled',true).addClass('d-none')
              if (response[0]==1){
                Swal.fire({
                  text: response[1],
                  icon: 'success'
                }).then((result) => {
                  if (result.isConfirmed) {
                    $("#editarUsuario").modal('hide');
                  }
                });
              }else{
                Swal.fire({
                  text: response[1],
                  icon: 'error'
                });
              }
            },
            error=>{
              console.error(error)
              Swal.fire({
                text: error[1],
                icon: 'error'
              });
            }
          )
        }
      });
    }

  }
}
