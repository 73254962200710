import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalsConstants } from '../commons/global-constants';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })

export class LiquidacionService {

    constructor(private http:HttpClient, private router:Router,private _constants:GlobalsConstants, private session:SessionService,) { }

    API_URI = this._constants.API_address + "Autoliquidacion"
    //API_URI = this._constants.appConfig + "Api/Autoliquidacion"
    //API_URI = "Api/Autoliquidacion"


    statusSelfAssessment:boolean=false;
    responseObj:any={"anio": "","trimestre": "","estado": false}

    verifySelfAssessment(){
        // Verifica trimestre Abierto:true | Cerrado:false
        let header = this.session.getStorageSession();
        let coordinates = this.session.getCoordinatesSession().split(';');
        let object = {"IdSector": Number(coordinates[0]),"IdEntidad": Number(coordinates[1])}
        return this.http.post(`${this.API_URI}/Inicio`,object,{'headers':header});
    }

    verifyStatusProcess(){
        // Verificar estado proceso de la entidad.
        let header = this.session.getStorageSession();
        let coordinates = this.session.getCoordinatesSession().split(';');
        let object = {"IdSector": Number(coordinates[0]),"IdEntidad": Number(coordinates[1])}
        return this.http.post(`${this.API_URI}/ValidarProcesoEntidad`,object,{'headers':header});
        //return 0;
    }

    getDataBaseCobro(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarBaseCobro`, object, { 'headers': header });
    }

    getDataBaseCobroTramiteData(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarBaseCobroTramiteData`, object, { 'headers': header });
    }

    getValueFogafin(object:any){
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/ObtenerValorCuenta`,object,{'headers':header});
    }

    saveDataBaseCobro(object:any){
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GuardarBaseCobroMes`,object,{'headers':header});
    }

    verifyReglas(object){
        // Verifica si puede pasar al siguiente paso de la autoliquidacion.
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/ValidarEntidad`,object,{'headers':header});
    }

    verifyStep(currentStep:number,object){
        // Verifica si puede pasar al siguiente paso de la autoliquidacion.
        let header = this.session.getStorageSession();
        let stepStatus:any;
        switch(currentStep){
            case 0:
            stepStatus = true //return this.http.post(`${this.API_URI}/ValidarEntidad`,object,{'headers':header});
            case 1:
            stepStatus = true
            case 2:
            stepStatus = true
            case 3:
            stepStatus = true
            case 4:
            stepStatus = false
        }
    }

    goToStep(step){
        let coordinates = this.session.getCoordinatesSession().split(';');
        switch(step){
            case 0:
            this.router.navigate(['validacionreglamento']);
            break;
            case 1:
            if (coordinates[0]=='28'){
                this.router.navigate(['basecobrosedpe']);
            }else{
                this.router.navigate(['basecobro']);
            }
            break;
            case 2:
            this.router.navigate(['calificacion']);
            break;
            case 3:
            this.router.navigate(['liquidacion']);
            break;
            case 4:
            this.router.navigate(['enviarliquidacion']);
            break;
        }
    }

    getComentariosByPaso(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarComentarios`,object,{'headers':header});
    }

    sendComentarios(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/EnviarComentarios`,object,{'headers':header});
    }

    getCalificacion(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarCalificacionTrimestre`,object,{'headers':header});
    }

    saveDataCalificacion(object:any){
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GuardarCalificacionMes`,object,{'headers':header});
    }

    getDataLiquidacionNeta(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarLiquidacionNetaTrimestre`,object,{'headers':header});
    }

    getRepresentantes(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarRepresentantesLegales`,object,{'headers':header});
    }

    //getPDF(object: any) {
    //    let header = this.session.getStorageSession();
    //    return this.http.post(`${this.API_URI}/Descargar`, object, {'headers':header});
    //}

    getPDF(object: any, nombreEntidad, email, usuario) {
        console.log(this.API_URI);
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/Descargar?nombreEntidad=${nombreEntidad}&email=${email}&usuario=${usuario}&forma=1`, object, {'headers':header});
    }

    ///* Descarga la carta */
    //getPDFDescargarCartaPagoSeguroDepositoCliente(object: any, origen) {
    //    console.log(object);
    //    let header = this.session.getStorageSession();
    //    return this.http.post(`${this.API_URI}/DescargarCPSDC?nombreEntidad =${origen}`, object, { 'headers': header });
    //}

    saveFilePDF(object: any) {
        let header = this.session.getStorageSession();
        return true//this.http.post(`${this.API_URI}/Cargar`,object,{'headers':header});
    }

    validatePasos(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/PasosCompletados`,object,{'headers':header});
    }

    getReport(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/ReporteAutoliquidacion`, object, { 'headers': header });
    }

    //************************************************************//
    //getReportCliente(object: any) {
    //getReportCliente(IdSector, IdEntidad, anioInicial, TrimestreInicial, anioFinal, TrimestreFinal) {
    getReportCliente(object: any) {
        //return this.http.post(`${this.API_URI}/ReporteAutoliquidacionCliente?idSector=${IdSector}&idEntidad=${IdEntidad}&anioInicial=${anioInicial}&TrimestreInicial=${TrimestreInicial}&anioFinal=${anioFinal}&TrimestreFinal=${TrimestreFinal}`, null);
        return this.http.post(`${this.API_URI}/ReporteAutoliquidacionCliente`, object);
    }

    fileExportarCliente(object: any) {
        let header = this.session.getStorageSession();
        //return this.http.post(`${this.API_URI}/ExportarReporteAutoliquidacionCliente/?sector=${sector}`, object);
        return this.http.post(`${this.API_URI}/ExportarReporteAutoliquidacionCliente`, object, { 'headers': header });
    }

    //*******Opcion 2 carga Data del detalle de la liquidacion
    getDataLiquidacionNetaCliente(object: any) {

        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarLiquidacionNetaTrimestreCliente`, object, { 'headers': header });
    }

    //forma=1
    //return this.http.post(`${this.API_URI}/DetalleLiquidacionCliente`, object);
    getDetalleLiquidacionCliente(object: any) {
        let header = this.session.getStorageSession();
        console.log(header);
        return this.http.post(`${this.API_URI}/DetalleLiquidacionCliente`, object, { 'headers': header });
    }

    getPDFClienteDOC(object: any) {
        let header = this.session.getStorageSession();
        //return this.http.post(`${this.API_URI}/DescargarClienteDOC`, object, { 'headers': header });
        //return this.http.post(`${this.API_URI}/Descargar?nombreEntidad=null&email=null&usuario=null&forma=2`, object, { 'headers': header });
        //forma=2
        return this.http.post(`${this.API_URI}/PDFClienteDOC`, object, { 'headers': header });
    }

    getRepresentantesCliente(IdSector, IdEntidad) {
        return this.http.post(`${this.API_URI}/CargarRepresentantesLegalesCliente/?idSector=${IdSector}&idEntidad=${IdEntidad}`, null);
    }

    getPDFCliente(IdSector, IdEntidad, Anio, Trimestre) {
        return this.http.post(`${this.API_URI}/DescargarCliente?idSector=${IdSector}&idEntidad=${IdEntidad}&anio=${Anio}&trimestre=${Trimestre}`, null);
    }

    //getPDFCliente(object: any) {
    //    let header = this.session.getStorageSession();
    //    return this.http.post(`${this.API_URI}/DescargarCliente`, object, { 'headers': header });
    //}

    //************************************************************//
    fileExportar(object: any) {
    let header = this.session.getStorageSession();
    return this.http.post(`${this.API_URI}/ExportarReporteAutoliquidacion`,object,{'headers':header})
  }

    CargarCartaPDF(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/CargarCarta`,object,{'headers':header});
    }

    ValidarCartaPDF(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/ValidarCarta`,object,{'headers':header});
    }

    RadicarCartaPDF(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/RadicarCarta`, object,{'headers':header});
    }

    validarEstadoProceso(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/EstadoProceso`,object,{'headers':header});
    }

}
