import { Component, OnInit, Input} from '@angular/core';
import {NgForm} from '@angular/forms';
import { FechasService } from 'src/app/services/fechas.service';
import {AdminComponent} from '../admin/admin.component'
import * as moment from 'moment';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'adm-fechas',
  templateUrl: './fechas-form.component.html',
})
export class FechasComponent implements OnInit{ 
  anioTrimestre={anio:'',trimestre:''}
  fecha:any={id:0, anio:'', trimestre:'', fechaInicial:'', fechaFinal:''};
  @Input() anios: any;
  
  constructor(private fechaService:FechasService,private admin : AdminComponent){}

  ngOnInit(){
    $('[data-toggle="tooltip"]').tooltip('hide')

  }
  
  getFecha(){
    this.anioTrimestre.anio=this.fecha.anio;
    this.anioTrimestre.trimestre=this.fecha.trimestre;
  
    if(this.anioTrimestre.anio!=''&&this.anioTrimestre.trimestre){
      //console.log(this.anioTrimestre)
      this.fechaService.getFecha(this.anioTrimestre).subscribe(
        response=> {
          let objFecha :any;
          objFecha = response;
          this.fecha.id = objFecha.id;
          this.fecha.fechaInicial = moment(objFecha.fechaInicial).format('YYYY-MM-DD');
          this.fecha.fechaFinal = moment(objFecha.fechaFinal).format('YYYY-MM-DD');
        },
        error=>{
          console.error(error)
          Swal.fire({
            text: error[1],
            icon: 'error'
          });
        }
      ) 
    }
  }

  onSubmit(fecha) {
    fecha.fechaInicial = fecha.fechaInicial+'T00:00:00'
    fecha.fechaFinal = fecha.fechaFinal+'T23:59:59'
    //console.log(fecha)
      this.fechaService.saveFecha(fecha).subscribe(
      response=>{
        if (response[0]==1){
          $("#FechasModal").modal('hide');
          this.fecha={id:0, anio:'', trimestre:'', fechaInicial:'', fechaFinal:''}          
          Swal.fire({
            text: response[1],
            icon: 'success' 
          });
        }else{
          Swal.fire({
            text: response[1],
            icon: 'error'
          });
        }
      },
      error=>console.error(error,fecha)
    ) 
  }
}
