import { Component, OnInit, Input} from '@angular/core';
import {NgForm, PatternValidator} from '@angular/forms';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario'
import {UsuariosComponent} from '../usuarios/usuarios.component'

declare var $: any;

@Component({
  selector: 'crear-usuario',
  templateUrl: './usuario-crear.component.html',
})
export class UsuarioCrearComponent implements OnInit{
  @Input() sectores:any;
  entidades:any;
  @Input() objusuario: any={
    id: 0,
    idSector:'',
    nombreSector:'',
    idEntidad:'',
    nombreEntidad:'',
    nombreContacto:'',
    email:'',
    telefono:'',
    celular:'',
    password:'',
    activo:false,
    bloqueado:false,
    administrador:false,
    consulta:false,
    restablecer:false,
  };
  constructor(private usuarioService:UsuariosService,private usuarios: UsuariosComponent){}

  ngOnInit(){
  }

  usrAdm()
  {
    if ($('input[name="administrador"]').prop('checked'))
    {
      this.objusuario.idSector='';
      this.objusuario.idEntidad='';
      $("#sector").prop('disabled', 'disabled');
      $("#entidad").prop('disabled', 'disabled');
    } else {
      $("#sector").removeAttr('disabled');
      $("#entidad").removeAttr('disabled');
    }
  }

  sectorChange(){
    
    this.usuarioService.getEntidades(this.objusuario.idSector, "false").subscribe(
      response=>{
        this.objusuario.idEntidad='';
        this.entidades = response;
      },
      err=>console.error(err)
    )
  }

  validaEmail(){

    'use strict';
    var EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (this.objusuario.email.match(EMAIL_REGEX))
    {
      let payload = {email:this.objusuario.email}
      this.usuarioService.validaEmail(payload).subscribe(
        response=>{
          if(response[0]=='0'){
            $("#emailMessaje").htm('Usuario ya existe');
            $("#emailMessaje").removeClass('d-none');
          }else{
            $("#emailMessaje").addClass('d-none');
          }
        },
        err=>console.error(err)
      )
    }
    else
    {
      $("#emailMessaje").html('Correo inválido');
      $("#emailMessaje").removeClass('d-none');
      $('input[name="email"]').focus();
      $('input[name="email"]').select();
    }
  }



  onSubmit(objusuario) {

    'use strict';
    var EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    if (this.objusuario.email.match(EMAIL_REGEX))
    {
      delete objusuario.fechaCreacion;
      delete objusuario.fechaModificacion;  

      Swal.fire({
        html:'<b>Confirmación de creación</b><br>Confirma la creación del usuario?' ,
        icon: 'warning', 
        showCancelButton: true,       
        confirmButtonText: 'Si, Crear',
        cancelButtonText: 'Cancelar'

      }).then((result) => {
        if (result.isConfirmed) {
          objusuario.nombreEntidad = $('#entidad').val()!=""? $('#entidad option:selected').text():'';
          objusuario.nombreSector =   $('#sector').val()!=""? $('#sector option:selected').text():'';
          objusuario.idEntidad = $('#entidad').val()!=""? objusuario.idEntidad:0;
          objusuario.idSector =   $('#sector').val()!=""? objusuario.idSector:0;
          objusuario.bloqueado =  objusuario.bloqueado==null?false:objusuario.bloqueado
          objusuario.activo =  objusuario.activo==null?false:objusuario.activo
          objusuario.consulta =  objusuario.consulta==null?false:objusuario.consulta
          objusuario.restablecer =  objusuario.restablecer==null?false:objusuario.restablecer
          objusuario.administrador =  objusuario.administrador==null?false:objusuario.administrador
          this.usuarioService.createUsuario(objusuario).subscribe(
            response=>{
            //console.log(response);      
              this.usuarios.getUsuarios()
              $('.check').prop('checked', false);
              $('#editar').attr('disabled',true);
              $('#cancelar').attr('disabled',true).addClass('d-none')
              $('#eliminar').attr('disabled',true).addClass('d-none')
              if (response[0]==1){
                Swal.fire({
                  text: response[1],
                  icon: 'success',        
                  confirmButtonText: 'Ok'
                }).then((result) => {
                  if (result.isConfirmed) {
                    $("#agregarUsuario").modal('hide');
                  }
                });
              }else{
                Swal.fire({
                  text: response[1],
                  icon: 'error'
                });
              }
            },
            error=>{
              console.error(error)
              Swal.fire({
                text: error[1],
                icon: 'error'
              });
            }
          ) 
        }
      });
    }
    else
    {
      $("#emailMessaje").html('Correo inválido');
      $("#emailMessaje").removeClass('d-none');
      $('input[name="email"]').focus();
      $('input[name="email"]').select();
    }
  }
}
