import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { SessionService } from './session.service';
import{ GlobalsConstants } from '../commons/global-constants';
import {Usuario} from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http:HttpClient, private session:SessionService, private _constants:GlobalsConstants) { }

  API_URI = this._constants.API_address+"Usuarios/"

  getUsuarios(pagina){
    let sessionId = window.localStorage.getItem('sessionId')
    let headers = new HttpHeaders()
    .set('sessionId', sessionId)
    .set('Access-Control-Allow-Origin','*')
    .set('pagina',pagina)
    return this.http.get(`${this.API_URI}Listar`, {'headers':headers})
  }

  getUsuariosFilter(parametro){
    let header = this.session.getStorageSession();
    return this.http.get(`${this.API_URI}Listar`, {'headers':header})
  }

  getUsuario(id:any){
    let header = this.session.getStorageSession();
    return this.http.get(`${this.API_URI}Ver/${id}`, {'headers':header})
  }

  getPassword(){
    let header = this.session.getStorageSession();
    return this.http.get(`${this.API_URI}GenerarContrasena/`, {'headers':header})
  }

  getSectores(){
    let header = this.session.getStorageSession();
    return this.http.get(`${this.API_URI}ListarSectores/`, {'headers':header})
  }

  validaEmail(payload:any){
    let header = this.session.getStorageSession();
    return this.http.post(`${this.API_URI}validar/`,payload, {'headers':header})
  }

  getEntidades(id:any, busqueda:any){
    let sessionId = window.localStorage.getItem('sessionId')
    let headers = new HttpHeaders()
    .set('sessionId', sessionId)
    .set('busqueda', busqueda)
    return this.http.get(`${this.API_URI}ListarEntidades/${id}`, {'headers':headers})
  }

  searchUsuario(search:any, page:any, filter:any){
    let sessionId = window.localStorage.getItem('sessionId')
    let headers = new HttpHeaders()
    .set('sessionId', sessionId)
    .set('Access-Control-Allow-Origin','*')
    .set('pagina',page)
    .set('sector',filter.sector)
    .set('entidad',filter.entidad)
    .set('activo',filter.activo)
    .set('bloqueado',filter.bloqueado)
    .set('administrador',filter.administrador)
    return this.http.get(`${this.API_URI}Buscar/${search}`, {'headers':headers})
  }

  updateUsuario(usuario: any){
    let header = this.session.getStorageSession();
    usuario.idEntidad = parseInt(usuario.idEntidad);
    usuario.idSector = parseInt(usuario.idSector);
    return this.http.post(`${this.API_URI}Editar/`,usuario, {'headers':header})
  }

  createUsuario(usuario: any){
    let header = this.session.getStorageSession();
    usuario.idEntidad = parseInt(usuario.idEntidad);
    usuario.idSector = parseInt(usuario.idSector);
    return this.http.post(`${this.API_URI}Crear/`,usuario, {'headers':header})
  }

  deleteUsuario(id:any){
    let header = this.session.getStorageSession();
    return this.http.delete(`${this.API_URI}Eliminar/${id}`, {'headers':header} )
  }

  fileExportar(param:any){
    let header = this.session.getStorageSession();
    let query = `${param.type}`;
    param.search!=''?query+=`/${param.search}`:'';
    return this.http.get(`${this.API_URI}Exportar/${query}`, {'headers':header})
  }
}
