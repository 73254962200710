import { Component, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'

declare var $: any;

@Component({
    selector: 'app-basescobroSedpes',
    templateUrl: './basescobroSedpes.component.html',
})
export class BasesCobroSedpesComponent {
    titulo = "Paso 1 - Cálculo de base de cobro - SEDPE";
    requiredType = "2"
    step = 1;
    nextStep = false;
    baseCobroReqObj: any = { IdSector: 0, IdEntidad: 0, Anio: "", Trimestre: "", };
    periodo = [];
    coordinates = [];
    objMes1: any;
    objMes2: any;
    objMes3: any;
    monthName = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    nameMes1: any;
    nameMes2: any;
    nameMes3: any;
    objBaseCobro: any = {
        anio: "",
        idEntidad: 0,
        idSector: 0,
        meses: []
    }
    active: any = false;
    inactive: boolean = true;
    respTramiteData: any = [];

    constructor(private sessionService: SessionService, private router: Router, private liquidacionService: LiquidacionService) { }

    ngOnInit() {
        this.sessionService.verifySession()
        this.sessionService.verifyRequired(this.requiredType);
        this.periodo = window.localStorage.getItem('periodo').split('-');
        this.coordinates = window.localStorage.getItem('coordinates').split(';');
        this.baseCobroReqObj.IdSector = Number(this.coordinates[0]);
        this.baseCobroReqObj.IdEntidad = Number(this.coordinates[1]);
        this.baseCobroReqObj.Anio = this.periodo[1];
        this.baseCobroReqObj.Trimestre = this.periodo[0];
        let payload = this.baseCobroReqObj;
        //this.liquidacionService.validarEstadoProceso(this.baseCobroReqObj).subscribe(
        //  response=>{
        //    setTimeout(() => {
        //      let resp=response;
        //      this.active = resp;
        //      this.inactive = !this.active;
        //    if (resp){
        //      $('a.action').addClass('d-none')
        //    }else{
        //      $('a.action').removeClass('d-none')
        //    }
        //  }, 750);
        //  },
        //  error=>{
        //    console.error(error)
        //  }
        //)
        this.getDataBaseCobro();
        this.getDataBaseCobroTramiteData();
        $('[data-toggle="tooltip"]').tooltip('hide')

    }

    getDataBaseCobroTramiteData() {
        this.liquidacionService.getDataBaseCobroTramiteData(this.baseCobroReqObj).subscribe(
            response => {
                this.respTramiteData = response;
            }
        )
    }

    getDataBaseCobro() {
        this.liquidacionService.getDataBaseCobro(this.baseCobroReqObj).subscribe(
            response => {
                setTimeout(() => {
                    this.objBaseCobro = response;
                    this.objMes1 = this.objBaseCobro.meses[0].cuentas;
                    this.objMes2 = this.objBaseCobro.meses[1].cuentas;
                    this.objMes3 = this.objBaseCobro.meses[2].cuentas;
                    this.nameMes1 = this.monthName[Number(this.objBaseCobro.meses[0].mes)]
                    this.nameMes2 = this.monthName[Number(this.objBaseCobro.meses[1].mes)]
                    this.nameMes3 = this.monthName[Number(this.objBaseCobro.meses[2].mes)]
                    $('#sendComment').attr('disabled', !this.objBaseCobro.comtcompl)
                }, 500);
            },
            error => console.error(error)
        )
    }

    validateAccount(cuenta, indice) {
        if (!this.active) {
            let mes = Number(this.objBaseCobro.meses[indice].mes)
            this.baseCobroReqObj.mes = mes;
            this.baseCobroReqObj.Codigo = cuenta;
            let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
            this.liquidacionService.getValueFogafin(this.baseCobroReqObj).subscribe(
                response => {
                    let objResponse: any;
                    objResponse = response;
                    this.objBaseCobro.meses[indice].cuentas[posCuenta].valor = Number($(`#${cuenta}_${indice}`).val());
                    this.objBaseCobro.meses[indice].cuentas[posCuenta].estado = 1;
                    this.objBaseCobro.meses[indice].cuentas[posCuenta].valorFogafin = objResponse.valor;
                    this.objBaseCobro.meses[indice].cuentas[posCuenta].coincide = this.objBaseCobro.meses[indice].cuentas[posCuenta].valorFogafin == Number(this.objBaseCobro.meses[indice].cuentas[posCuenta].valor) ? true : false;
                    this.objBaseCobro.meses[indice].cuentas[posCuenta].coincide ? this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario = "" : null;
                },
                error => console.error(error)
            )
            console.log(this.objBaseCobro.meses[indice].cuentas[posCuenta])
            console.log(this.objBaseCobro)
        }

    }

    setComentario() {
        let cuenta = $('#mdl_comment').attr('data-cuenta');
        let indice = $('#mdl_comment').attr('data-indice');
        let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
        this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario = $(`#mdl_comment`).val();
        $('#comentario').modal('hide');
    }

    showCommentInput(cuenta, indice) {
        let posCuenta = this.objBaseCobro.meses[indice].cuentas.findIndex(row => row.codigo == cuenta)
        let comentario = this.objBaseCobro.meses[indice].cuentas[posCuenta].comentario;
        $('#mdl_comment').val(comentario == "" ? "" : comentario)
        $('#mdl_comment').attr({ 'data-cuenta': cuenta, "data-indice": indice });
        $('#comentario').modal('show');

    }

    sendComment() {
        this.router.navigate(['/solicitudes/1']);
    }

    saveBaseCobro() {
        this.liquidacionService.saveDataBaseCobro(this.objBaseCobro).subscribe(
            response => {
                let objResponse: any = { estado: false };
                objResponse = response;
                if (objResponse.estado) {
                    Swal.fire({
                        text: "Datos guardados satisfactoriamente",
                        icon: 'success'
                    });
                    this.getDataBaseCobro();
                } else {
                    Swal.fire({
                        text: "No se guardaron los datos",
                        icon: 'info'
                    });
                }

            },
            error => console.error(error)
        )
    }

    Validate() {

        this.liquidacionService.validatePasos(this.objBaseCobro).subscribe(
            response => {
                setTimeout(() => {
                    let objResponse: any = { estado: false };
                    objResponse = response;

                    if (!objResponse.estado) {
                        Swal.fire({
                            text: "Los datos ingresados en la base de cobro presenta diferencias respecto a los datos procesados por Fogafín. Por favor revise la información y de continuar la diferencia envíe sus comentarios a través de la opción Enviar comentarios",
                            icon: 'info'
                        });
                    } else {
                        this.router.navigate(['/liquidacion']);

                    }
                }, 500);
            },
            error => console.error(error)
        )
    }

    missingOneDecimalCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (x && x.length === 1) return true;
        return false;
    }

    missingAllDecimalsCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (!x) return true;
        return false;
    }

    formatoNumero(nStr) {

        let r: String;
        if (nStr === '') return '';
        if (Number.isNaN(nStr)) return '';
        let x, x1, x2, rgx, y1, y2;
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? ',' + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }

        /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
        if (x1.indexOf(',') !== -1) {
            y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

            y2 = x1.split(',');
            r = y2[0] + y1;
        } else {
            r = x1 + x2;
            if (this.missingOneDecimalCheck(r)) return r += '0';
            if (this.missingAllDecimalsCheck(r)) return r += ',00';
        }

        return r;
    }
}
