import { Component, HostListener } from '@angular/core';
import { LoginService } from './services/login.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  constructor(private loginService:LoginService){}
  /* @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    event.preventDefault(()=>{
      let session={id:window.localStorage.getItem('sessionId')};
      this.loginService.logout(session);

    });
  // any other code / dialog logic
  } */
}
