import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import { GlobalsConstants } from '../../commons/global-constants';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-liquidacion-client',
    templateUrl: './liquidacion-client.component.html',
    providers: [LiquidacionService]
})

export class LiquidacionClientComponent implements OnInit {
    titulo = "Detalle liquidaci\u00f3n";
    requiredType = "1";

    idSector = window.localStorage.getItem('sectorEntidad');
    idEntidad = window.localStorage.getItem('idEntidad');
    anio = this.activatedRoute.snapshot.params["anioInicialPd"];
    trimestre = this.activatedRoute.snapshot.params["trimestreInicialPd"];

    mes1 = 1;
    mes2 = 2;
    mes3 = 3;

    idRepresentante = 0;

    objReport: any = {
        trimestre: '',
        anio: ''
    }

    helps: any = {
        help1: 'Calificaci\u00f3n trimestral:"La calificaci\u00f3n trimestral est\u00e1 determinada por el promedio de las calificaciones mensuales de todas las categor\u00edas que componen la calificaci\u00f3n. Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n vigente del Seguro de Dep\u00f3sitos en la secci\u00f3n de Normatividad". ',
        help2: 'Porcentaje de devoluci\u00f3n: "El porcentaje de devoluci\u00f3n depende de la calificaci\u00f3n obtenida por la entidad. Un porcentaje negativo, implica un monto adicional sobre la prima, un valor positivo, resulta en una devoluci\u00f3n a la entidad. Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n vigente del Seguro de Dep\u00f3sitos en la secci\u00f3n de Normatividad".',
        help3: 'Coeficiente de ajuste: "Es un componente anual de la prima que podr\u00e1 cobrar Fogaf\u00edn con el fin de alcanzar el valor objetivo de la reserva del Seguro de Dep\u00f3sitos. El valor que aplica al cobro actual lo puede consultar en la Circular Externa de Coeficiente de Ajuste en la secci\u00f3n de Normatividad".',
        help4: 'Base de cobro: "La base de cobro de la prima est\u00e1 conformada por el promedio simple del saldo mensual de las acreencias amparadas por el Seguro de Dep\u00f3sitos durante el trimestre. Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n vigente del Seguro de Dep\u00f3sitos en la secci\u00f3n de Normatividad".',
        help5: 'Prima base: "La prima base trimestral a cargo de la entidad corresponde al 0.075% (0.3% anual) de la base de cobro. Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n vigente del Seguro de Dep\u00f3sitos en la secci\u00f3n de Normatividad".',
        help6: 'Devoluci\u00f3n o cobro adicional: "La devoluci\u00f3n o cobro adicional de prima se calcula con base en la calificaci\u00f3n obtenida siguiendo la metodolog\u00eda definida por Fogaf\u00edn en la resoluci\u00f3n vigente para tal efecto. Cada calificaci\u00f3n tiene un porcentaje de devoluci\u00f3n definido y este porcentaje se multiplica por el valor de la prima base para calcular el monto correspondiente de devoluci\u00f3n o cobro adicional". ',
        help7: 'Valor por coeficiente de ajuste: "El valor a pagar por concepto de coeficiente de ajuste se calcula multiplicando el coeficiente definido por Fogaf\u00edn para el a\u0148o objeto de pago de la prima por el Valor neto antes de coeficiente". ',
        help8: 'Valor neto a pagar: "Valor a pagar por concepto de prima de Seguro de Dep\u00f3sitos luego de aplicar el porcentaje de devoluci\u00f3n y el coeficiente de ajuste a la prima base". ',
        help9: 'Base de cobro: "La base de cobro de la prima est\u00e1 conformada por el promedio simple de los saldos diarios de las acreencias amparadas por el Seguro de Dep\u00f3sitos durante el trimestre. Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n de Seguro de Dep\u00f3sitos de las SEDPES en la secci\u00f3n Normatividad".',
        help10: 'Valor neto a pagar: "La prima corresponde al 0.0375% de la base de cobro (0.15% anual). Para m\u00e1s informaci\u00f3n consulte la Resoluci\u00f3n de Seguro de Dep\u00f3sitos de las SEDPES en la secci\u00f3n Normatividad"',
        help11: 'Valor neto antes de coeficiente: "Es la multiplicaci\u00f3n del coeficiente de ajuste con el valor de prima neto antes del coeficiente"',
    }

    objRepresentantes: any = [];
    active: any;
    report: any = [];
    reporteDetalletLiquidacion: any = [];

    liquidacionNetaObj: any = { idSector: this.idSector, idEntidad: this.idEntidad, anio: this.anio, trimestre: this.trimestre};
    //liquidacionNetaObjCalificacion: any = { idSector: this.idSector, idEntidad: this.idEntidad, anio: this.anio, trimestre: this.trimestre, forma: 1 };
    //liquidacionNetaObjCarta: any = { idSector: this.idSector, idEntidad: this.idEntidad, anio: this.anio, trimestre: this.trimestre, forma: 2};
    //liquidacionNetaObjDoc: any = { IdSector: this.idSector, IdEntidad: this.idEntidad, Anio: this.anio, Trimestre: this.trimestre };

    constructor(private activatedRoute: ActivatedRoute, private liquidacionService: LiquidacionService, private _constants: GlobalsConstants) { }

    ngOnInit() {
        console.log(this._constants.SERVER_address);

        if (this.trimestre == "1") {
            this.mes1 = 1;
            this.mes2 = 2;
            this.mes3 = 3;
        }
        else if (this.trimestre == "2") {
            this.mes1 = 4;
            this.mes2 = 5;
            this.mes3 = 6;
        }
        else if (this.trimestre == "3") {
            this.mes1 = 7;
            this.mes2 = 8;
            this.mes3 = 9;
        }
        else if (this.trimestre == "4") {
            this.mes1 = 10;
            this.mes2 = 11;
            this.mes3 = 12;
        }

        /*Lista internamente los representantes para la descarga del documento*/
        //this.liquidacionService.getRepresentantesCliente(this.idSector, this.idEntidad).subscribe(res => { this.objRepresentantes = res; })

        //* Carga la data para el cuadro de la calificacion y de base cobro
        this.liquidacionService.getDataLiquidacionNetaCliente(this.liquidacionNetaObj).subscribe(res => { this.report = res; })

        //* Carga la data para el cuadro de indicadores
        this.liquidacionService.getDetalleLiquidacionCliente(this.liquidacionNetaObj).subscribe(res => {
            this.reporteDetalletLiquidacion = res;
            if (this.report == '') {
                Swal.fire({
                    html: `<b>No hay informaci&#243;n para el trimestre consultado`,
                    icon: 'error'
                }).then((result) => {
                    if (result.isConfirmed) {
                        let reportUrl = `/Home`;
                        window.location.href = reportUrl;
                    }
                });
            }
            document.getElementById("element").innerHTML = this.reporteDetalletLiquidacion;
            $(".encabezadoDetalle").hide();
        })
    }

    selectAgent() {$('#enviar').modal('show');}

    downloadPDF() {
        //console.log(this._constants.SERVER_address);
        //this.liquidacionService.getPDFCliente(this.idSector, this.idEntidad, this.anio, this.trimestre).subscribe(

        this.liquidacionService.getPDFClienteDOC(this.liquidacionNetaObj).subscribe(
            response => {
                //let pdfAddress = this._constants.SERVER_address + response
                let pdfAddress = this._constants.SERVER_address + response
                console.log(pdfAddress);
                window.open(pdfAddress, '_blank');
                $('input[type="radio"]').prop('checked', false);
                $('#enviar').modal('hide');
            },
            error => console.error(error)
        )
    }

    missingOneDecimalCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (x && x.length === 1) return true;
        return false;
    }

    missingAllDecimalsCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (!x) return true;
        return false;
    }

    formatoNumero(nStr) {
        if (nStr === '') return '';
        let x, x1, x2, rgx, y1, y2;
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? ',' + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }

        /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
        if (x1.indexOf(',') !== -1) {
            y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

            y2 = x1.split(',');
            x = y2[0] + y1;
        } else {
            x = x1 + x2;
            if (this.missingOneDecimalCheck(x)) return x += '0';
            if (this.missingAllDecimalsCheck(x)) return x += ',00';
        }

        return x;
    }
}
