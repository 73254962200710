import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
//import { HttpClient} from '@angular/common/http';
import{ GlobalsConstants } from '../commons/global-constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http:HttpClient,private _constants:GlobalsConstants,private router:Router) { }
  API_URI = this._constants.API_address+"Usuarios"
  sessionCounter:any;

  setSessionTime(){

    // this.sessionCounter = setInterval(() => {
    //   let getTime = window.localStorage.getItem('sessionTime')
    //   let remaining = Number(getTime);
    //   let fecha = new Date(window.localStorage.getItem('fechaInicio'));
    //   fecha.setMinutes(fecha.getMinutes() + remaining + 1);
    //   let ahora = new Date();
    //   if (fecha < ahora ){
    //     localStorage.clear()
    //     this.router.navigate(['']);
    //   }
    //   else
    //   {
    //     window.localStorage.setItem('fechaInicio', ahora.toString())
    //     if (remaining > 1&&getTime!=null){
    //       remaining = remaining - 1;
    //       window.localStorage.setItem('sessionTime',(remaining).toString())
    //     }
    //     else
    //     {
    //       clearInterval(this.sessionCounter);
    //       window.localStorage.clear()
    //       this.router.navigate(['']);
    //     }
    //   }

      
    // }, 60000); 
  }

  clearSessionTimeOut(){
    clearInterval(this.sessionCounter);
    window.localStorage.clear()
    this.router.navigate([''])
  }

    makeLogin(payload: any) {
        let sessionId = window.localStorage.getItem('sessionId')
        let headers = new HttpHeaders().set('sessionId', sessionId).set('Access-Control-Allow-Origin', '*')

    return this.http.post(`${this.API_URI}/Login`,payload)
  }

  verifyNewPassword(payload: any){
    //console.log(this.API_URI)   
    return this.http.post(`${this.API_URI}/ValidarPassword`,payload)
  }

  verifyCurrPassword(payload: any){
    //console.log(this.API_URI)   
    return this.http.post(`${this.API_URI}/ValidarPasswordActual`,payload)
  }

  logout(payload: any){
    //console.log(this.API_URI)   
    return this.http.post(`${this.API_URI}/Logout`,payload)
  }

  changeContrasena(payload: any){   
    return this.http.post(`${this.API_URI}/RestablecerContrasena`,payload)
  }

  recoveryContrasena(email: any){   
    return this.http.post(`${this.API_URI}/RecuperarContrasena`,email)
  }

  verifyToken(token: any){   
    return this.http.post(`${this.API_URI}/ValidarToken`,token)
  }

}
