import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { GlobalsConstants } from '../commons/global-constants';
import { Observable } from 'rxjs';
import { FechasClientes } from '../models/fechas-clientes';


@Injectable({
    providedIn: 'root'
})
export class FechasService {
    constructor(private http: HttpClient, private session: SessionService, private _constants: GlobalsConstants) { }

    API_URI = this._constants.API_address + "Fechas/"

    getFecha(anioTrimestre) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}Cargar/`, anioTrimestre, { 'headers': header })
    }

    saveFecha(fecha: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}Guardar/`, fecha, { 'headers': header })
    }
    //************* carga los a�os en el listbox inicial 
    listarAnios(IdSector, opcion) {
        return this.http.post(`${this.API_URI}CargarAniosInicial?idSector=${IdSector}&opcion=${opcion}`, null)
    }
    //************* carga los trimestres segun el a�o seleccionado
    //*********** carga los trimestres iniciales de la primera opcion
    CargarTrimestreAnioInicial(anio, sector) {
        return this.http.post(`${this.API_URI}CargarTrimestreAnioInicial?anio=${anio}&sector=${sector}`, null);
    }
    //*********** carga los a�os segun la seleccion del a�o inicial de la opcion 1
    listarAniosFiltro(anioFiltro) {
        return this.http.post(`${this.API_URI}CargarAniosFinal?anioInicial=${anioFiltro}`, null)
    }
    ListarTrimestreAnioFiltro(anioInicial, trimestreInicial, anioFinal) {
        return this.http.post(`${this.API_URI}CargarTrimestreAnioFiltro?anioInicial=${anioInicial}&trimestreInicial=${trimestreInicial}&anioFinal=${anioFinal}`, null);
    }
    //*********** opcion 2
    ListarTrimestreAnio(IdSector, IdEntidad, anioTrimestre) {
        return this.http.post(`${this.API_URI}CargarTrimestreAnioInicial`, null);
    }
}



