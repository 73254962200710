import { Component, OnInit, Input} from '@angular/core';
import {NgForm, FormGroup} from '@angular/forms';
import Swal from 'sweetalert2';
import { RepresentantesService } from '../../services/representantes.service';

declare var $: any;

@Component({
  selector: 'adm-representantes',
  templateUrl: './representantes-file.component.html'
})
export class RepresentantesFileComponent implements OnInit{
  @Input() file:any;
  messaje:string="Haga click para selecionar un archivo";

  constructor(private representantesService:RepresentantesService){}

  ngOnInit(){
    this.messaje="Haga click para selecionar un archivo";
    $("#RepresentantesModal").modal('hide');
    $('#fileRepresentante').val('');
  }

  setFile(){
    this.messaje=$('#fileRepresentante')[0].files[0].name;
  }


  close(){
    this.messaje="Haga click para selecionar un archivo";
    $("#RepresentantesModal").modal('hide');
    $('#fileRepresentante').val('');
  }

  onSubmit() {
    let file = new FormData();
    file.append('file',$('#fileRepresentante')[0].files[0]);

    let timeout = $('#fileRepresentante')[0].files[0].size>1000000?$('#fileRepresentante')[0].files[0].size/2000:$('#fileRepresentante')[0].files[0].size/1000; 
    console.log(timeout);

    Swal.fire({
      html: '<b>¿Confirma realizar el cargue del archivo?<b/>',
      icon: 'question',
      focusConfirm: true,
      showCancelButton:true,
      confirmButtonText:'Enviar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval
        Swal.fire({
          html: 'Cargando su archivo',
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        })
        this.representantesService.saveFileRepresentantes(file).subscribe(
          response=>{
            let status:any={estado:false,detalle:""}
            status = response;
            if (status.estado){
              setTimeout(() => {
                $("#RepresentantesModal").modal('hide');
                $('#fileRepresentante').val('');
                this.messaje="Haga click para selecionar un archivo";
                Swal.fire({
                  text: 'Archivo cargado satisfactoriamente',
                  icon: 'success' 
                });
              }, timeout);
            }else{
              Swal.fire({
                html: `<b>'No se pudo cargar el archivo, revise su estructura.</b>`,
                icon: 'error'
              });
            }
          },
          error=>{
            console.error(error)
            $("#RepresentantesModal").modal('hide');
            $('#fileRepresentante').val('');
            this.messaje="Haga click para selecionar un archivo";
            Swal.fire({
              text: "Se presento un error " + error[0],
              icon: 'error'
              
            });
          } 
        )
      }
    });
  }

}
