import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service'

declare var $: any;

@Component({
  selector: 'app-login',
  template: '<div></div>',
})
export class InicioComponent implements OnInit {
  constructor( private sessionService:SessionService){
  }
  session =  window.localStorage.getItem('sessionId');
  ngOnInit(){
    this.sessionService.verifySession()
    if(this.session!=null){
      this.sessionService.goOwnHome()
    }

  }
}
