import { Component, OnInit, Input} from '@angular/core';
import {NgForm} from '@angular/forms';
import Swal from 'sweetalert2';
import { ParametrosService } from '../../services/parametros.service';
import {AdminComponent} from '../admin/admin.component'

declare var $: any;

@Component({
  selector: 'adm-parametros',
  templateUrl: './parametros-form.component.html',
})
export class ParametrosComponent implements OnInit{
  @Input() parametros:any;

  constructor(private parametrosService:ParametrosService,private admin: AdminComponent){}

  ngOnInit(){}

  onSubmit(parametros) {
      parametros.diasAvisoBloqueo= parseInt(parametros.diasAvisoBloqueo)
      parametros.diasBloqueoUsuario	= parseInt(parametros.diasBloqueoUsuario)
      parametros.diasCambioContrasena= parseInt(parametros.diasCambioContrasena)
      parametros.id= parseInt(parametros.id)
      parametros.numeroIntentosFallidos= parseInt(parametros.numeroIntentosFallidos)
      parametros.tiempoCerrarSesion	= parseInt(parametros.tiempoCerrarSesion)
      parametros.tiempoTokenValido = parseInt(parametros.tiempoTokenValido)
    
      this.parametrosService.updateParametros(parametros).subscribe(
        response=>{
          //console.log(response)
          $("#ParametrosModal").modal('hide');
          this.admin.getParametros()
          if (response[0]==1){
            Swal.fire({
              text: response[1],
              icon: 'success'
            });
          }else{
            Swal.fire({
              text: response[1],
              icon: 'error'
            });
          }
        },
        error=>{
          console.error(error)
          Swal.fire({
            text: error[1],
            icon: 'error'
          });
        }
      )
    
  }
}
