import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { LoginComponent } from '../Login/login.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;

@Component({
  selector: 'restablecer-form',
  templateUrl: './restablecer-form.component.html'
})
export class RestablecerComponent implements OnInit{
  recaptcha:string='';
  @Input() email:any={email:''};

  constructor(private loginService:LoginService,private login: LoginComponent){}

  ngOnInit(){

  }
  resolved(captchaResponse: string) {
    this.recaptcha=captchaResponse;
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    setTimeout(()=>{
      grecaptcha.reset(0);
      this.recaptcha='';
    },40000)
  }

  onSubmit(email) {  
      console.log("Email: " + email);


      if (this.recaptcha != '') {
      this.loginService.recoveryContrasena(email).subscribe(
          response => {
              let status: any = { estado: false };
              status = response;

              ///$("#restablecerClave").modal('hide');
              console.log("Resultado: " + status.estado);

          if (status.estado){
            Swal.fire({
              text: 'Se ha enviado a la dirección registrada un correo electrónico con la nueva clave de ingreso temporal. Por favor siga las instrucciones del correo para restablecer la contraseña',
              icon: 'success'
            });
          }
          else {
            Swal.fire({
              text: 'Nuestro sistema no tiene registro del usuario ingresado o puede estar inactivo. Por favor valide la información ingresada',
              icon: 'info'
            });
          }
          this.email={email:''} 
        },
        error=>{
          console.error(error)
          Swal.fire({
            text: error[1],
            icon: 'error'
          });
        }
      )
    }else{
      Swal.fire({
        text: 'Por favor active recapcha para continuar.',
        icon: 'info'
      });
    }
    
  }


  

}