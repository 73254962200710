import { Component, OnInit, Input } from '@angular/core';
import { GlobalsConstants } from '../../commons/global-constants';
import { ActivatedRoute } from '@angular/router';
import { UsuariosService } from '../../services/usuarios.service';
import { BorrarLiquidacionService } from '../../services/borrarliquidacion.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-borrar-liquidacion',
    templateUrl: './borrar-liquidacion.component.html'
})
export class BorrarLiquidacionComponent implements OnInit {
    @Input() usuario: any = { idSector: '', idEntidad: '' };
    @Input() sectores: any;
    @Input() entidades: any;

    anio = '';
    trimestre = '';

    calificacion = 0;
    baseCobro = 0;
    EstadoRadicacion = 0;
    radicado = '';

    report: any = [];//************* 
    reporte: any = [];//************* 
    responseObj: any = { "anio": "", "trimestre": "", "IdSector": "", "IdEntidad": "", "justificacion": "", "radicado": "" }

    constructor(private borrarLiquidacionService: BorrarLiquidacionService, private usuariosService: UsuariosService, private globals: GlobalsConstants, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.inicio();
        $("#borrar").removeAttr('checked');
        $("#consultas").removeAttr('checked');
    }

    borrado() {
        $("#borrado").attr('hidden', false);
        $("#consultar").attr('hidden', true);
        this.getDataInicial();
    }

    consulta() {
        this.limpiaDataOnclick();
        $("#consultar").attr('hidden', false);
        $("#borrado").attr('hidden', true);
    }

    inicio() {
        $("#borrar").prop('checked', false);
        $("#consultas").prop('checked', false);
        $("#borrado").attr('hidden', true);
        this.limpiaDataOnclick();
    }

    limpiaDataOnclick() {
        this.usuario.idSector = '';
        this.usuario.idEntidad = '';
        $("#justificacion").val("");
    }

    getDataInicial() {
        this.getAnioTrimestre();
        this.getSectoresData();
    }

    getAnioTrimestre() {
        this.borrarLiquidacionService.getAnioTrimestre().subscribe(res => {
            this.report = res;

            this.anio = this.report[0]["anio"];
            this.trimestre = this.report[0]["trimestre"];

            if (this.report[0]["fecha"] === "N") {
                $("#borrado").attr('hidden', true);
                $("#consultar").attr('hidden', true);
                Swal.fire({
                    text: 'No se encuentra un periodo disponible para procesar.',
                    icon: 'warning'
                });
            }
            else {
                $("#borrado").attr('hidden', false);
                $("#consultar").attr('hidden', true);
            }
        });
    }

    getSectoresData() {//************* Carga la lista de sectores
        this.usuario.idEntidad = '';

        this.usuariosService.getSectores().subscribe(
            response => {this.sectores = response;},
            err => { console.error(err) }
        )
    }

    sectorChange() {//***************** Carga la lista de entidades seg�n el sector seleccionado
        this.usuario.idEntidad = '';
        if (!this.usuario.administrador) {
            this.usuariosService.getEntidades(this.usuario.idSector, "false").subscribe(
                response => {
                    this.usuario.idEntidad = '';//******************** Para que seleccione el primer registro de la lista
                    this.entidades = response;
                },
                err => console.error(err)
            )
        }
    }

    entidadOnchange() {
        this.responseObj = { "anio": this.anio, "trimestre": this.trimestre, "IdSector": this.usuario.idSector, "IdEntidad": this.usuario.idEntidad }
        this.borrarLiquidacionService.GetDataLiquidacion(this.responseObj).subscribe(res => {
            this.reporte = res;
            this.calificacion = this.reporte[0]["calificacion"];
            this.baseCobro = this.reporte[0]["baseCobro"];
            this.EstadoRadicacion = this.reporte[0]["EstadoRadicacion"];
            this.radicado = this.reporte[0]["radicado"];
        });
    }

    onSubmit() {
        let sector = this.usuario.idSector;
        let entidad = this.usuario.idEntidad;
        let justificacion = $("#justificacion").val();

        let anio = this.anio;
        let trimestre = this.trimestre;
        let entidadNombre = $("#entidad").find('option:selected').text();
        //************************ Validaciones
        if (sector !== '' && entidad !== '' && justificacion.length<50) {
            Swal.fire({
                text: 'El texto tiene que tener por lo menos 50 caracteres',
                icon: 'warning'
            });
            return false;
        }

        if (sector === '' && entidad === '' && justificacion==='') {
            Swal.fire({
                text: 'Los campos Sector, Entidad y Justificaci\u00f3n, son obligatorios',
                icon: 'warning'
            });
            return false;
        }
        else if (sector !== '' && entidad === '' && justificacion==='') {
            Swal.fire({
                text: 'Los campos Entidad y Justificaci\u00f3n, son obligatorios',
                icon: 'warning'
            });
            return false;
        }
        else if (sector !== '' && entidad !== '' && justificacion === '') {
            Swal.fire({
                text: 'La Justificaci\u00f3n, es obligatoria',
                icon: 'warning'
            });
            return false;
        }
        else if (sector !== '' && entidad === '' && justificacion !== '') {
            Swal.fire({
                text: 'La Entidad, es obligatoria',
                icon: 'warning'
            });
            return false;
        }

        else if (sector === '' && entidad !== '' && justificacion === '') {
            Swal.fire({
                text: 'Los campos Sector y Justificaci\u00f3n, son obligatorios',
                icon: 'warning'
            });
            return false;
        }
        else if (sector !== '' && entidad !== '' && justificacion === '') {
            Swal.fire({
                text: 'La Justificaci\u00f3n, es obligatoria',
                icon: 'warning'
            });
            return false;
        }
        else if (sector === '' && entidad !== '' && justificacion !== '') {
            Swal.fire({
                text: 'El Sector, es obligatorio',
                icon: 'warning'
            });
            return false;
        }

        else if (sector === '' && entidad === '' && justificacion !== '') {
            Swal.fire({
                text: 'Los campos Sector y Entidad, son obligatorios',
                icon: 'warning'
            });
            return false;
        }
        else if (sector !== '' && entidad === '' && justificacion !== '') {
            Swal.fire({
                text: 'La Entidad, es obligatoria',
                icon: 'warning'
            });
            return false;
        }
        else if (sector === '' && entidad !== '' && justificacion !== '') {
            Swal.fire({
                text: 'El Sector, es obligatorio',
                icon: 'warning'
            });
            return false;
        }
        //************************ Fin Validaciones
        var texto = "<table style='text-align:justify;'>" +
            "<tr><td>Se borrar\u00e1n los siguientes datos de la liquidaci\u00f3n del a\u0148o " + anio + ", trimestre " + trimestre + ", para la entidad " + this.usuario.idEntidad + " del sector " + this.usuario.idSector + " " + entidadNombre + ":</td></tr>" +
            "<tr><td>" +
            "<br> - La marcaci\u00f3n de inicio de la liquidaci\u00f3n " +
            "<br> - (" + this.baseCobro + ") Datos de la base de cobro" +
            "<br> - (" + this.calificacion + ") Datos de la calificaci\u00f3n";
        if (this.EstadoRadicacion==1)texto += "<br> - Radicado " + this.radicado + ".";
        texto += "<br></td></tr>" +
            "<tr><td style='text-align:center; color:red;'><br> &iquest;Est\u00e1 seguro de realizar el borrado?</td></tr>" +
            "</table>"

        Swal.fire({
            title:"Validaci\u00f3n",
            html: texto,
            icon:'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',

        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteData(justificacion);
            }
        });

    }

    deleteData(justificacion) {
        let radicadoRes = "";
        if (this.EstadoRadicacion == 1) radicadoRes =this.radicado;

        this.responseObj = { "anio": this.anio, "trimestre": this.trimestre, "IdSector": this.usuario.idSector, "IdEntidad": this.usuario.idEntidad, "justificacion": justificacion, "radicado": radicadoRes}
        this.borrarLiquidacionService.borrarLiquidacion(this.responseObj).subscribe(res => {
            if (res === true) {
                Swal.fire({
                    text: 'Los datos fueron borrados correctamente.',
                    icon: 'success'
                }).then((result) => {
                    this.limpiaDataOnclick();
                });
            }
            else {
                Swal.fire({
                    text: 'La entidad no tiene informaci\u00f3n para procesar.',
                    icon: 'warning'
                });
            }
        });
    }

    onConsulta() {
        let reportUrl = `/Listaliquidacionesborradas/`;
        window.location.href = reportUrl;
    }
}
