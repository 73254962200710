import { Component, OnInit, Input  } from '@angular/core';
import { GlobalsConstants } from '../../commons/global-constants';
import { ActivatedRoute } from '@angular/router';
import { BorrarLiquidacionService } from '../../services/borrarliquidacion.service';
import Swal from 'sweetalert2';


declare var $: any;

@Component({
  selector: 'app-listaliquidacionesborradas',
  templateUrl: './listaliquidacionesborradas.component.html',
})
export class ListaliquidacionesborradasComponent implements OnInit {
    @Input() liquidacion: any = { anio: '', trimestre: '', idSector: '', idEntidad: '' };
    @Input() anios: any;
    @Input() trimestres: any;
    @Input() sectores: any;
    @Input() entidades: any;

    responseObj: any = { "anio": "", "trimestre": "", "IdSector": "", "IdEntidad": "", "campo":"" }

    report: any = [];
    listaAnios: any = [];
    listaTrimestres: any = [];
    listaSectores: any = [];
    listaEntidades: any = [];
    filtro: any = [];

    fileExportLink: any;
    titulo = "Consulta hist\u00f3rico de borrado de liquidaciones";
  constructor(private borrarLiquidacionService: BorrarLiquidacionService, private globals: GlobalsConstants, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.getCargaAnios();
        this.getCargaTrimestres();
        this.getCargaSectores();
        this.getCargaEntidades();
        this.getReport();
      }

    getReport() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad }
        this.borrarLiquidacionService.getReport(this.responseObj).subscribe(
            response => {
                this.report = response
            },
            err => {
                console.error(err)
            }
        )
    }

    //************** Carga listas
    getCargaAnios() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad, "campo": "Anio"}
        this.borrarLiquidacionService.getAniosLiquidaciones(this.responseObj).subscribe(res => {
            this.listaAnios = res;
        });
    }

    getCargaTrimestres() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad, "campo": "Trimestre"}
        this.borrarLiquidacionService.getAniosLiquidaciones(this.responseObj).subscribe(res => {
            this.listaTrimestres = res;
        });
    }

    getCargaSectores() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad, "campo": "IdSector"}
        this.borrarLiquidacionService.getAniosLiquidaciones(this.responseObj).subscribe(res => {
            this.listaSectores = res;
        });
    }

    getCargaEntidades() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad, "campo": "IdSector, IdEntidad"}
        this.borrarLiquidacionService.getAniosLiquidaciones(this.responseObj).subscribe(res => {
            this.listaEntidades = res;
        });
    }

    //*************** Filtros
    dataChangeA() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad }
        this.getCargaTrimestres();
        this.getCargaSectores();
        this.getCargaEntidades();

        this.getReport();
    }

    dataChangeB() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad }
        this.getCargaAnios();
        this.getCargaSectores();
        this.getCargaEntidades();

        this.getReport();
    }

    dataChangeC() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad }
        this.getCargaAnios();
        this.getCargaTrimestres();
        this.getCargaEntidades();

        this.getReport();
    }

    dataChangeD() {
        this.responseObj = { "anio": this.liquidacion.anio, "trimestre": this.liquidacion.trimestre, "IdSector": this.liquidacion.idSector, "IdEntidad": this.liquidacion.idEntidad }
        this.getCargaAnios();
        this.getCargaTrimestres();
        this.getCargaSectores();

        this.getReport();
    }

    fileExportar() {
        this.borrarLiquidacionService.ExportarReporteBorrado(this.responseObj).subscribe(
            response => {
                let fileAddress = this.globals.SERVER_address + response;
                window.open(fileAddress, '_blank');
            }
        )
    }
}
