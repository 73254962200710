import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service'
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
    selector: 'app-contrasena',
    templateUrl: './contrasena.component.html',
})

export class ContrasenaComponent implements OnInit {
    passwordObj: any = {
        password: '',
        token: ''
    }
    token: string;
    currentPassword: string = '';
    password: string = '';
    passwordConfirm: string = '';
    newPassword: any = { estado: false };
    currPasswordValid: boolean = false;

    constructor(private loginService: LoginService, private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        //$("#formPC").removeClass('d-none').addClass('d-flex')
        let tokken = { token: this.activatedRoute.snapshot.params.token };
        this.token = this.activatedRoute.snapshot.params.token;
        if (tokken.token == '') {
            //console.log('Cambiar contraseña sin token')
        } else {
            console.log('Llega para cambiar contraseña 1')
            console.log("Token: " + this.token)
            console.log("Token: " + this.password)
            //console.log("Verificar token!!!")
            this.tokenVerify(tokken);
            var sessionCounter = setInterval(() => {
                this.loginService.verifyToken(tokken).subscribe(
                    response => {
                        let tokenResp: any = { estado: false, detalle: '' };
                        tokenResp = response
                        if (!tokenResp.estado) {
                            clearInterval(sessionCounter);
                            Swal.fire({
                                html: `<b>Sesión Finalizada</b><br>${tokenResp.detalle}`,
                                icon: 'error'
                            }).then((result) => {
                                localStorage.clear()
                                window.location.href = '/';
                            });
                        }
                    }, err => console.error(err)
                )
            }, 300000);
        }
        $('[data-toggle="tooltip"]').tooltip('hide')
    }
    tokenVerify(tokken) {
        this.loginService.verifyToken(tokken).subscribe(
            response => {
                let tokenResp: any = { estado: false, detalle: '' };
                tokenResp = response
                if (!tokenResp.estado) {
                    Swal.fire({
                        html: `<b>Verificación Fallida</b><br>${tokenResp.detalle}`,
                        icon: 'error'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.clear()
                            window.location.href = '/';
                        }
                    });
                } else {
                    $("#formPC").removeClass('d-none').addClass('d-flex')
                }
            }, err => {
                console.error(err);
                //window.location.href = '/';
            }
        )
    }

    backToLogin() {
        localStorage.clear()
        window.location.href = '/';
    }

    validateCurrentPassword() {
        $('#enviar').attr('disabled', true)
        this.password = '';
        this.passwordConfirm = '';
        let credenciales: object = {
            email: window.localStorage.getItem('emailUsuario'),
            password: this.currentPassword
        };
        this.loginService.verifyCurrPassword(credenciales).subscribe(
            response => {
                let currPasswordResponse: any = { estado: false }
                currPasswordResponse = response
                if (!currPasswordResponse.estado) {
                    $('#compareMessaje').removeClass('d-none').addClass('d-flex')
                    $('#messaje').html('Contraseña actual invalida.')
                    $('#currentPassword').focus();
                } else {
                    $('#compareMessaje').removeClass('d-flex').addClass('d-none')
                    $('#messaje').html('')
                    this.currPasswordValid = currPasswordResponse.estado;
                }
            },
            error => {
                console.error(error)
                Swal.fire({
                    html: '<b>Error de conexión</b><br>El servicio de cambio de contraseña no se encuentra disponible en este momento.<br>Intente mas tarde.',
                    icon: 'error'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.clear()
                        this.router.navigate(['']);
                    }
                });
            }
        )
    }

    comparePasswords() {
        let valid = this.passwordChars(this.password)
        console.log(this.password === this.passwordConfirm);
        if (valid) {
            if ((this.password === this.passwordConfirm) && this.currPasswordValid) {
                $('#compareMessaje').removeClass('d-flex').addClass('d-none')
                $('#messaje').html('')
                $('#enviar').removeAttr('disabled')
            } else {
                $('#compareMessaje').removeClass('d-none').addClass('d-flex')
                $('#enviar').attr('disabled', true)
                $('#messaje').html('Las contraseñas ingresadas no coinciden, verifique el dato ingresado.')
            }
        }
    }

    getStrong(level) {
        console.log(this.charValidator(this.password))
        if (this.charValidator(this.password)) {
            if (level >= 2 && (this.password === this.passwordConfirm && this.currPasswordValid)) {
                $('#compareMessaje').removeClass('d-flex').addClass('d-none')
                $('#enviar').removeAttr('disabled')
            }
        }
    }

    charValidator(password) {
        let credenciales: object = {
            email: window.localStorage.getItem('emailUsuario'),
            password: this.password
        };
        var res;
        this.loginService.verifyNewPassword(credenciales).subscribe(
            response => {
                this.newPassword = response;

                if (this.currPasswordValid) {
                    if (password.match(/[a-z]/g) && password.match(/[A-Z]/g) && password.match(/[0-9]/g) && password.match(/[^a-zA-Z\d]/g) && password.length >= 8 && !this.newPassword.estado) {
                        $('#compareMessaje').removeClass('d-flex').addClass('d-none')
                        $('#messaje').html('')
                        $('#enviar').removeAttr('disabled')
                        res = true;
                    } else if (password.length >= 8 && !this.newPassword.estado) {
                        $('#compareMessaje').removeClass('d-none text-center').addClass('d-flex')
                        $('#messaje').html('La contraseña debe contener al menos un carácter de los siguientes tipos: minúscula, mayúscula, numero y carácter especial ')
                        $('#enviar').attr('disabled', true)
                        res = false;
                    } else if (this.newPassword.estado) {
                        $('#compareMessaje').removeClass('d-none text-center').addClass('d-flex')
                        $('#messaje').html('Debe ingresar una contraseña diferente a las últimas tres usadas.')
                        $('#enviar').attr('disabled', true)
                    }
                    this.passwordConfirm = '';
                }

            }, error => {
                console.error(error)
                Swal.fire({
                    html: '<b>Error de conexión</b><br>El servicio de cambio de contraseña no se encuentra disponible en este momento.<br>Intente mas tarde.',
                    icon: 'error'
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.clear()
                        window.location.href = '/';
                    }
                });
            }
        )
        return res;
    }

    passwordChars(password) {
        let res = false;
        if (this.currPasswordValid) {
            if (password.match(/[a-z]/g) && password.match(/[A-Z]/g) && password.match(/[0-9]/g) && password.match(/[^a-zA-Z\d]/g) && password.length >= 8 && !this.newPassword.estado) {
                res = true;
            } else if (password.length >= 8 && !this.newPassword.estado) {
                $('#compareMessaje').removeClass('d-none text-center').addClass('d-flex')
                $('#messaje').html('La contraseña debe contener al menos un carácter de los siguientes tipos: minúscula, mayúscula, numero y carácter especial ')
                $('#enviar').attr('disabled', true)
                res = false;
            }
        }
        return res;
    }

    onSubmit() {
        let passwordObj = {
            password: this.password,
            token: this.token
        }
        this.loginService.changeContrasena(passwordObj).subscribe(
            response => {
                let status: any = { estado: false };
                status = response;
                if (status.estado) {
                    Swal.fire({
                        text: 'Contraseña cambiada correctamente',
                        icon: 'success'
                    }).then((result) => {
                        localStorage.clear()
                        window.location.href = '/';
                    });
                } else {
                    Swal.fire({
                        text: 'No se pudo completar la solicitud',
                        icon: 'error'
                    }).then((result) => {
                        localStorage.clear()
                        window.location.href = '/';
                    });
                }
            },
            error => {
                console.error(error)
                Swal.fire({
                    text: error,
                    icon: 'error'
                });
            }
        )
    }
}
