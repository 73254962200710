import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { GlobalsConstants } from '../commons/global-constants';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class BorrarLiquidacionService {
    constructor(private http: HttpClient, private session: SessionService, private _constants: GlobalsConstants) { }

    API_URI = this._constants.API_address + "Autoliquidacion"

    //************************** Borrado de liquidaciones
    getAnioTrimestre() {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GetAnioTrimestre`, "", { 'headers': header });
    }

    GetDataLiquidacion(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GetDataLiquidacion`, object, { 'headers': header });
    }

    borrarLiquidacion(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/BorrarLiquidacion`, object, { 'headers': header });
    }

    getReport(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GetReporteBorrado`, object, { 'headers': header });
    }

    //************************** Consulta de Borrado de liquidaciones
    getAniosLiquidaciones(object: any) {
        let header = this.session.getStorageSession();
        return this.http.post(`${this.API_URI}/GetAniosLiquidaciones`, object, { 'headers': header });
    }

    ExportarReporteBorrado(object: any) {
        let header = this.session.getStorageSession();
        //return this.http.post(`${this.API_URI}/ExportarReporteAutoliquidacionCliente/?sector=${sector}`, object);
        return this.http.post(`${this.API_URI}/ExportarReporteBorrado`, object, { 'headers': header });
    }
}
