import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RecaptchaModule } from "ng-recaptcha";

import{ GlobalsConstants } from './commons/global-constants';
import {UsuariosService} from './services/usuarios.service';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavMenuAltComponent } from './components/nav-menu-alt/nav-menu-alt.component';
import { LoginComponent } from './components/Login/login.component';
import { HomeComponent } from './components/Home/home.component';
import { NormaComponent } from './components/Norma/norma.component';
import { ContactComponent } from './components/contact/contact.component';
import { AutoliquidacionComponent } from './components/autoliquidacion/autoliquidacion.component';
import { ValidacionregComponent } from './components/validacionreg/validacionreg.component';
import { BasesCobroComponent } from './components/basescobro/basescobro.component';
import { CalificacionComponent } from './components/calificacion/calificacion.component';
import { LiquidacionComponent } from './components/liquidacion/liquidacion.component';
import { EnviarLiquidacionComponent } from './components/enviarliquidacion/enviarliquidacion.component';
import { SolicitudComponent } from './components/solicitudes/solicitudes.component';
import { AdminComponent } from './components/admin/admin.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuarioCrearComponent } from './components/usuario-crear/usuario-crear.component';
import { UsuarioEditarComponent } from './components/usuario-editar/usuario-editar.component';
import { FechasComponent } from './components/fechas-form/fechas-form.component';
import { ParametrosComponent } from './components/parametros-form/parametros-form.component';
import { RestablecerComponent } from './components/restablecer-form/restablecer-form.component';
import { InicioComponent } from './components/Inicio/inicio.component';
import { NavFootComponent } from './components/nav-foot/nav-foot.component';
import { RepresentantesFileComponent } from './components/representantes-file/representantes-file.component';
import { ContrasenaComponent } from './components/cambiarContrasena/contrasena.component';
import { BasesCobroSedpesComponent } from './components/basescobroSedpes/basescobroSedpes.component';
import { ReporteComponent } from './components/reporte_liquidacion/reporte.component';

import { AppConfigService } from './services/app-config.service';
import { FechasClientComponent } from './components/fechas-client/fechas-client.component';
import { ReporteClienteComponent } from './components/reporte-cliente/reporte-cliente.component';
import { LiquidacionClientComponent } from './components/liquidacion-client/liquidacion-client.component';

import { APP_INITIALIZER } from '@angular/core';
import { PagosAnualesComponent } from './components/pagos-anuales/pagos-anuales.component';
import { BorrarLiquidacionComponent } from './components/borrar-liquidacion/borrar-liquidacion.component';
import { ListaliquidacionesborradasComponent } from './components/listaliquidacionesborradas/listaliquidacionesborradas.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    NavMenuAltComponent,
    LoginComponent,
    NormaComponent,
    ContactComponent,
    FechasClientComponent,
    ReporteClienteComponent,
    HomeComponent,
    AdminComponent,
    AutoliquidacionComponent,
    ValidacionregComponent,
    BasesCobroComponent,
    BasesCobroSedpesComponent,
    CalificacionComponent,
    LiquidacionComponent,
    EnviarLiquidacionComponent,
    SolicitudComponent,
    UsuariosComponent,
    UsuarioCrearComponent,
    UsuarioEditarComponent,
    ParametrosComponent,
    FechasComponent,
    RestablecerComponent,
    InicioComponent,
    NavFootComponent,
    RepresentantesFileComponent,
    ContrasenaComponent,
    ReporteComponent,
    FechasClientComponent,
    ReporteClienteComponent,
    LiquidacionClientComponent,
    PagosAnualesComponent,
    BorrarLiquidacionComponent,
    ListaliquidacionesborradasComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    PasswordStrengthMeterModule,
    NgbModule,
    RecaptchaModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'administracion', component: AdminComponent, pathMatch: 'full' },
      { path: 'inicio', component: InicioComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'normatividad', component: NormaComponent, pathMatch: 'full' },
      { path: 'contactenos', component: ContactComponent, pathMatch: 'full' },
      { path: 'pagosAnuales/:anioPSD', component: PagosAnualesComponent, pathMatch: 'full' },
      { path: 'FechasClient', component: FechasClientComponent, pathMatch: 'full' },
        { path: 'BorrarLiquidacion', component: BorrarLiquidacionComponent, pathMatch: 'full' },
        { path: 'Listaliquidacionesborradas', component: ListaliquidacionesborradasComponent, pathMatch: 'full' },
      { path: 'reporte-cliente/:anioInicial/:trimestreInicial/:anioFinal/:trimestreFinal', component: ReporteClienteComponent },
      { path: 'liquidacion-client/:anioInicialPd/:trimestreInicialPd', component: LiquidacionClientComponent },
      { path: 'inicioliquidacion', component: AutoliquidacionComponent, pathMatch: 'full' },
      { path: 'validacionreglamento', component: ValidacionregComponent, pathMatch: 'full' },
      { path: 'basecobro', component: BasesCobroComponent, pathMatch: 'full' },
      { path: 'basecobrosedpe', component: BasesCobroSedpesComponent, pathMatch: 'full' },
      { path: 'calificacion', component: CalificacionComponent, pathMatch: 'full' }, 
      { path: 'liquidacion', component: LiquidacionComponent, pathMatch: 'full' },
      { path: 'solicitudes/:paso', component: SolicitudComponent},
      { path: 'enviarliquidacion', component: EnviarLiquidacionComponent, pathMatch: 'full' },
      { path: 'usuarios', component: UsuariosComponent, pathMatch: 'full' },
      { path: 'cambiarContrasena/:token', component: ContrasenaComponent },
      { path: 'reporte/:sector/:anio/:trimestre', component: ReporteComponent },
      { path: '**', redirectTo: '' }
    ],{ relativeLinkResolution: 'corrected' })
  ],
  providers: [UsuariosService, GlobalsConstants, {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
