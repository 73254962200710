import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'
import {LiquidacionService } from '../../services/liquidacion.service'
declare var $: any;
@Component({
  selector: 'app-validacionreg',
  templateUrl: './validacionreg.component.html',
})
export class ValidacionregComponent {
  titulo = "Validación por reglamentación Seguro de Depósito";
  requiredType="2"
  step = 0;
  nextStep = false;
  verificacionObj={
    idSector:0,
    idEntidad:0,
    Anio: "",
    Trimestre: "",
    opciones:[]
  }
  objResponse:any={
    estado: false,
    detalle: "",
  }
  entity:string;

  constructor( private sessionService:SessionService, private router:Router, private liquidacionService:LiquidacionService){}
  ngOnInit(){
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    this.entity = window.localStorage.getItem('coordinates')!=null? window.localStorage.getItem('coordinates').split(';')[0]:'';
    let coordinates = this.sessionService.getCoordinatesSession().split(';');
    this.verificacionObj.idSector = Number(coordinates[0]);
    this.verificacionObj.idEntidad = Number(coordinates[1]);
    this.verificacionObj.Trimestre = window.localStorage.getItem('periodo')!=null? window.localStorage.getItem('periodo').split('-')[0]:'';
    this.verificacionObj.Anio = window.localStorage.getItem('periodo')!=null? window.localStorage.getItem('periodo').split('-')[1]:'';

  }

  selectOption(id){
    let selected = $(`#${id}`).prop('checked');
    let exist = this.verificacionObj.opciones.some(option=>option==id);
    let pos = this.verificacionObj.opciones.findIndex(option=>option==id);
    if (selected){
      !exist?this.verificacionObj.opciones.push(id):null;
    }else{
      this.verificacionObj.opciones.splice(pos, 1);
    }
  }

  verifyNextStep(){
    this.liquidacionService.verifyReglas(this.verificacionObj).subscribe(
      response=>{
        setTimeout(() => {
        this.objResponse=response;
        if(this.objResponse.estado){
          if(this.verificacionObj.idSector==28){
            this.router.navigate(['/basecobrosedpe']);
          }else{
            this.router.navigate(['/basecobro']);
          }
        }
        else{
          Swal.fire({
            html: `<p style="text-align:justify">Las siguientes opciones seleccionadas presentan diferencias respecto a los datos procesados por Fogafín. Por favor verifique su selección y en caso de continuar la incongruencia comuníquese con recaudos@fogafin.gov.co.</p> <p style="text-align:justify">` + this.objResponse.detalle + `</p>`,
            width: '800px'
          });
        }



		}, 500);
      },
      error=>console.error(error)
    )

  }
}
