import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'
import { GlobalsConstants } from '../../commons/global-constants';


declare var $: any;

@Component({
    selector: 'app-liquidacion',
    templateUrl: './liquidacion.component.html',
})
export class LiquidacionComponent implements OnInit {
    titulo = "Paso 3 - Liquidación neta";
    requiredType = "2";
    step = 3;

    nextStep = false;
    periodo = window.localStorage.getItem('periodo').split('-');
    coordinates = window.localStorage.getItem('coordinates').split(';');
    entity: string;
    urlPasoAnterior: string;
    textoPasoAnterior: string;
    responseEstadoLiquidacion: any;

    helps: any = {
        help1: 'Calificación trimestral: “La calificación trimestral está determinada por el promedio de las calificaciones mensuales de todas las categorías que componen la calificación. Para más información consulte la Resolución vigente del Seguro de Depósitos en la sección de Normatividad”. ',
        help2: 'Porcentaje de devolución: “El porcentaje de devolución depende de la calificación obtenida por la entidad. Un porcentaje negativo, implica un monto adicional sobre la prima, un valor positivo, resulta en una devolución a la entidad. Para más información consulte la Resolución vigente del Seguro de Depósitos en la sección de Normatividad”.',
        help3: 'Coeficiente de ajuste: “Es un componente anual de la prima que podrá cobrar Fogafín con el fin de alcanzar el valor objetivo de la reserva del Seguro de Depósitos. El valor que aplica al cobro actual lo puede consultar en la Circular Externa de Coeficiente de Ajuste en la sección de Normatividad”.',
        help4: 'Base de cobro: “La base de cobro de la prima está conformada por el promedio simple del saldo mensual de las acreencias amparadas por el Seguro de Depósitos durante el trimestre. Para más información consulte la Resolución vigente del Seguro de Depósitos en la sección de Normatividad”.',
        help5: 'Prima base: “La prima base trimestral a cargo de la entidad corresponde al 0.075% (0.3% anual) de la base de cobro. Para más información consulte la Resolución vigente del Seguro de Depósitos en la sección de Normatividad”.',
        help6: 'Devolución o cobro adicional: “La devolución o cobro adicional de prima se calcula con base en la calificación obtenida siguiendo la metodología definida por Fogafín en la resolución vigente para tal efecto. Cada calificación tiene un porcentaje de devolución definido y este porcentaje se multiplica por el valor de la prima base para calcular el monto correspondiente de devolución o cobro adicional”. ',
        help7: 'Valor por coeficiente de ajuste: “El valor a pagar por concepto de coeficiente de ajuste se calcula multiplicando el coeficiente definido por Fogafín para el año objeto de pago de la prima por el Valor neto antes de coeficiente”. ',
        help8: 'Valor neto a pagar: “Valor a pagar por concepto de prima de Seguro de Depósitos luego de aplicar el porcentaje de devolución y el coeficiente de ajuste a la prima base”. ',
        help9: 'Base de cobro: “La base de cobro de la prima está conformada por el promedio simple de los saldos diarios de las acreencias amparadas por el Seguro de Depósitos durante el trimestre. Para más información consulte la Resolución de Seguro de Depósitos de las SEDPES en la sección Normatividad”.',
        help10: 'Valor neto a pagar: “La prima corresponde al 0.0375% de la base de cobro (0.15% anual). Para más información consulte la Resolución de Seguro de Depósitos de las SEDPES en la sección Normatividad”',
        help11: 'Valor neto antes de coeficiente: “Es la multiplicación del coeficiente de ajuste con el valor de prima neto antes del coeficiente”',
    }

    liquidacionNetaObj: any = {
        IdSector: Number(this.coordinates[0]),
        IdEntidad: Number(this.coordinates[1]),
        Anio: this.periodo[1],
        Trimestre: this.periodo[0],
    };

    objRepresentantes: any = [];
    respTramiteData: any = [];
    active: any;
    objLiquidacionNeta: any;

    resp: any = [];

    constructor(private sessionService: SessionService, private router: Router, private liquidacionService: LiquidacionService, private _constants: GlobalsConstants) {
        this.objLiquidacionNeta = {
            CalificacionTrimestral: 0,
            PorcentajeDevolucion: 0,
            CoeficienteAjuste: 0,
            BaseCobro: 0,
            PrimaBase: 0,
            DevolucionCobroAdicional: 0,
            NetoAntesCoeficiente: 0,
            ValorCoeficiente: 0,
            NetoPagar: 0
        };
    }

    ngOnInit() {
        this.entity = window.localStorage.getItem('coordinates') != null ? window.localStorage.getItem('coordinates').split(';')[0] : '';
        this.liquidacionService.validatePasos(this.liquidacionNetaObj).subscribe(
            response => {
                let objResponse: any = { estado: false };
                objResponse = response;
                if (!objResponse.estado) {
                    if (this.entity == '28') {
                        this.router.navigate(['/basecobrosedpe']);
                    } else {
                        this.router.navigate(['/calificacion']);
                    }
                }
            },
            error => console.error(error)
        )
        this.sessionService.verifySession()
        this.sessionService.verifyRequired(this.requiredType)
        this.liquidacionService.getDataLiquidacionNeta(this.liquidacionNetaObj).subscribe(
            response => {
                this.objLiquidacionNeta = response;
                $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover', html: true })
            },
            error => console.error(error)
        )

        this.liquidacionService.getRepresentantes(this.liquidacionNetaObj).subscribe(
            response => {
                this.objRepresentantes = response;
                console.log(response);
            },
            error => console.error(error)
        )
        this.liquidacionService.validarEstadoProceso(this.liquidacionNetaObj).subscribe(
            response => {
                this.resp = response;
                if (this.resp.radicado != null) {
                    $('a.action').addClass('d-none');
                    this.active = true;
                }
                if (this.resp.estado == 0) {/*Bloquea si el proceso va en paso 3 liquidacion ok*/
                    //if (this.resp.estado == 1) {/*Boblea si el estado del proceso es=1 cuando las calificaciones qdan ok y se pasa a estado 3*/
                    //$('a.action').removeClass('d-none');
                };
            },
            error => { console.error(error) }
            /*
            this.liquidacionService.validarEstadoProceso(this.liquidacionNetaObj).subscribe(
                response => {
                    this.responseEstadoLiquidacion = response;
                    let resp: any = false;
                    resp = this.responseEstadoLiquidacion.estado;
                    this.active = resp;
                    if (resp) {
                        $('a.action').addClass('d-none')
                    }
                },
                error => {
                    console.error(error)
                }
                )
            */
        )
        if (this.entity == '28') {
            $('#basecobro').attr('title', this.helps.help9).tooltip('dispose').tooltip({ title: this.helps.help9 });
            $('#neto').attr('title', this.helps.help10).tooltip('dispose').tooltip({ title: this.helps.help10 });
            this.urlPasoAnterior = "/basecobrosedpe";
            this.textoPasoAnterior = "Paso 1 - Base de cobro";
        }
        else {
            $('#basecobro').attr('title', this.helps.help4).tooltip('dispose').tooltip({ title: this.helps.help4 });
            $('#neto').attr('title', this.helps.help8).tooltip('dispose').tooltip({ title: this.helps.help8 });
            this.urlPasoAnterior = "/calificacion";
            this.textoPasoAnterior = "Paso 2 - Porcentaje de devolución del cobro";
        }
        $('[data-toggle="tooltip"]').tooltip('hide')
        this.getDataBaseCobroTramiteData();
    }

    getDataBaseCobroTramiteData() {
        this.liquidacionService.getDataBaseCobroTramiteData(this.liquidacionNetaObj).subscribe(
            response => {
                this.respTramiteData = response;
            }
        )
    }

    selectAgent() {
        $('#enviar').modal('show');
        $('#downloadPDFD').addClass("d-none");
        $('#downloadPDF').removeClass("d-none");
    }

    downloadPDF() {
        this.liquidacionNetaObj.IdRepLegal = typeof $('input[type="radio"]:checked').val() != "undefined" ? Number($('input[type="radio"]:checked').val()) : '';
        if (this.liquidacionNetaObj.IdRepLegal != '') {
            $('#downloadPDF').addClass("d-none");
            $('#downloadPDFD').removeClass("d-none");
            this.liquidacionService.getPDF(this.liquidacionNetaObj, null, null, null).subscribe(
                response => {
                    let pdfAddress = this._constants.SERVER_address + response
                    //console.log(pdfAddress);
                    window.open(pdfAddress, '_blank');
                    $('input[type="radio"]').prop('checked', false);
                    $('#enviar').modal('hide');
                },
                error => console.error(error)
            )
        } else {
            Swal.fire({
                text: "Asegúrese de seleccionar un Representante Legal",
                icon: 'info'
            });
        }
    }

    validate() {

        this.router.navigate(['/enviarliquidacion/']);
    }
    missingOneDecimalCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (x && x.length === 1) return true;
        return false;
    }
    missingAllDecimalsCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (!x) return true;
        return false;
    }
    formatoNumero(nStr) {
        if (nStr === '') return '';
        let x, x1, x2, rgx, y1, y2;
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? ',' + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }

        /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
        if (x1.indexOf(',') !== -1) {
            y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

            y2 = x1.split(',');
            x = y2[0] + y1;
        } else {
            x = x1 + x2;
            if (this.missingOneDecimalCheck(x)) return x += '0';
            if (this.missingAllDecimalsCheck(x)) return x += ',00';
        }

        return x;
    }
}
