import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { SessionService } from './session.service';
import{ GlobalsConstants } from '../commons/global-constants';

@Injectable({
  providedIn: 'root'
})
export class RepresentantesService {

  constructor(private http:HttpClient, private session:SessionService,private _constants:GlobalsConstants) { }
  API_URI = this._constants.API_address+"Representantelegal/"

  saveFileRepresentantes(fileRepresentantes: any){
    let header = this.session.getStorageSession();
    return this.http.post(`${this.API_URI}Importar`,fileRepresentantes,{'headers':header});
  }
}
