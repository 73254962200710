import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'

declare var $: any;

@Component({
  selector: 'app-enviarliquidacion',
  templateUrl: './enviarliquidacion.component.html',
})
export class EnviarLiquidacionComponent implements OnInit {
  titulo = "Envío de liquidación";
  requiredType="2"
  step = 1;
  nextStep = false;
  messaje:string="Haga click para seleccionar un archivo";
  objValidar:any= {
    IdSector: 0,
    IdEntidad: 0,
    Anio:"" ,
    Trimestre:""
  }
    periodo: string[];

    resp: any = [];

  coordinates: string[];
  active: any=true;
  file:any;
  idRepresentante: any;
  activatedRoute: any;
  responseEstadoLiquidacion:any;

  constructor( private sessionService:SessionService, private router:Router, private liquidacionService:LiquidacionService){}

  ngOnInit(){
    
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    this.periodo = window.localStorage.getItem('periodo').split('-');
    this.coordinates = window.localStorage.getItem('coordinates').split(';');
    this.objValidar.IdSector=Number(this.coordinates[0]);
    this.objValidar.IdEntidad=Number(this.coordinates[1]);
    this.objValidar.Anio=this.periodo[1];
    this.objValidar.Trimestre=this.periodo[0];
    this.loadInfoLiquidacion();
    
    $('[data-toggle="tooltip"]').tooltip('hide')

  }

  setFile(){
    this.messaje=$('#fileRepresentante')[0].files[0].name;
  }

    loadInfoLiquidacion() {

        this.liquidacionService.validarEstadoProceso(this.objValidar).subscribe(
            response => {
                this.responseEstadoLiquidacion = response;
                if (this.responseEstadoLiquidacion.radicado != null) {/*Bloquea si el proceso va en paso 3 liquidacion ok*/
                    //if (this.resp.estado == 1) {/*Boblea si el estado del proceso es=1 cuando las calificaciones qdan ok y se pasa a estado 3*/
          $('button.action').addClass('d-none')
          $('input.action').attr('disabled',true)
                }
            },
            error => { console.error(error) }
        )


    //this.liquidacionService.validarEstadoProceso(this.objValidar).subscribe(
    //  response=>{
    //    this.responseEstadoLiquidacion = response;
    //    let resp:any = false;
    //    resp= this.responseEstadoLiquidacion.estado;
    //    this.active = resp;
    //    if (resp){
    //      //$('button.action').addClass('d-none')
    //      $('input.action').attr('disabled',true)
    //    }
    //  },
    //  error=>{
    //    console.error(error)
    //  }
    //)
  }

  close(){
    this.messaje="Haga click para selecionar un archivo";
    $('#fileRepresentante').val('');
  }

  onSubmit() {
    let file = new FormData();
    let status:any={estado:false,detalle:""}
    file.append('file',$('#fileRepresentante')[0].files[0]);

    let timeout = $('#fileRepresentante')[0].files[0].size>1000000?$('#fileRepresentante')[0].files[0].size/2000:$('#fileRepresentante')[0].files[0].size/1000;
    console.log(timeout);

    Swal.fire({
      html:  `<b>Confirmación de envío</b><br>Al enviar el formato de liquidación acepta que los valores reportados corresponden a la prima neta por concepto de Seguro de Depósitos para el trimestre ${this.objValidar.Trimestre} de ${this.objValidar.Anio}. Una vez recibida la notificación no se aceptan modificaciones a los valores reportados.`,
      icon: 'question',
      focusConfirm: true,
      showCancelButton:true,
      confirmButtonText:'Enviar',
      cancelButtonText:'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval
        Swal.fire({
          html: 'Cargando su archivo',
          timerProgressBar: true,
          didOpen: () => {Swal.showLoading()},
          willClose: () => {clearInterval(timerInterval)}
        })
        
        this.liquidacionService.CargarCartaPDF(file).subscribe(
          response=>{
            Swal.close();
            status = response;
            //console.log("status.estado: "+status.estado)
            if (status.estado){
              setTimeout(() => {
                this.liquidacionService.ValidarCartaPDF(this.objValidar).subscribe(
                  response=>{
                  setTimeout(() => {
                    let validar:any={estado:false}
                    validar = response
                    if (validar.estado){
                      this.radicarCarta();
                      this.messaje="Haga click para selecionar un archivo";
                      $('#fileRepresentante').val('');
                    }else{
                      Swal.fire({
                        html:  "<b>Firma Invalida.</b><br>No se pudo validar la firma del representante legal seleccionado en el documento, verifique y vuelva a cargar el archivo con la firma correcta.",
                        icon: 'error',
                        focusConfirm: true
                      });
                      this.messaje="Haga click para selecionar un archivo";
                      $('#fileRepresentante').val('');
                    }
                   }, 750);
                  },
                  error=>{console.error(error)}
                )
              }, timeout);
            }else{
              Swal.fire({
                html: `<b>No se cargó el archivo (validar ingresa a este error)</b><br>${status.detalle}`,
                icon: 'error'
              });
              this.messaje="Haga click para selecionar un archivo";
              $('#fileRepresentante').val('');
            }
          },
          error=>{
            //console.error(error)
            Swal.fire({text: error[1], icon: 'error'});
            this.messaje="Haga click para selecionar un archivo";
            $('#fileRepresentante').val('');
          }
        )
      }
    });
  }


  radicarCarta(){
      let timerInterval
      Swal.fire({
        html: 'Radicando el archivo',
        didOpen: () => {Swal.showLoading()},
        willClose: () => {clearInterval(timerInterval)}
      })
    this.liquidacionService.RadicarCartaPDF(this.objValidar).subscribe(
      response=>{
        Swal.close();
        let radicar:any={estado:false}
        radicar = response
        if (radicar.estado){
          let user = window.localStorage.getItem('nombreUsuario');
          Swal.fire({
              html: "<b>Su liquidación se recibió de forma exitosa.</b><br> Tenga en cuenta que el plazo para realizar el pago corresponde a los tres últimos días hábiles del mes<br><div style='display:inline-block;text-align:left;'><b>Fecha de liquidación: "+ radicar.fechaliquidacion +" </b><br><b>Usuario: "+ user +" </b><br><b>Representante Legal: " + radicar.representante +" </b><br><b>Radicado: "  + radicar.nroradicado + "</div> </b>",
            icon: 'success',
            focusConfirm: true
          })
          this.messaje="Haga click para selecionar un archivo";
          $('#fileRepresentante').val('');
          $("#enviarCarta").attr('disabled',true)
          this.loadInfoLiquidacion();
        }else{
          Swal.fire({
            html:  "<b>El envío del documento no se pudo completar.</b><br>No se pudo radicar el documento",
            icon: 'info',
            focusConfirm: true,
            showCancelButton:true,
            confirmButtonText:'Reintentar',
            cancelButtonText:'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.radicarCarta();
            }
          })
        }
      },
      error=>{
        console.error(error)
      }
    )
  }
}
