import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service'
declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  nombreUsuario:string;
  tipoUsuario:any;
  constructor(private router:Router, private loginService:LoginService){}

  ngOnInit(){
    this.getUserData()
    $('[data-toggle="tooltip"]').tooltip('hide')

  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  getUserData(){
    this.tipoUsuario =  window.localStorage.getItem('userType');
    let nombreUsuario =  window.localStorage.getItem('nombreUsuario');
    let nombreEntidad =  window.localStorage.getItem('nombreEntidad');
    
    //console.log(this.tipoUsuario)
    if(this.tipoUsuario=='1'){
      this.nombreUsuario = nombreUsuario
    }else{
      this.nombreUsuario = `${nombreUsuario} - ${nombreEntidad}`
    }
    }

  cerrarSesion(){
    let session={id:window.localStorage.getItem('sessionId')}
    this.loginService.logout(session).subscribe( 
      response=> {
        //console.log(response)
      },
      err=>console.error(err)
    )
    localStorage.clear()
    this.router.navigate(['']);

  }
}
