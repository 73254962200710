import { Component, OnInit } from '@angular/core';
import { FechasService } from '../../services/fechas.service';
import { ParametrosService } from '../../services/parametros.service';
import { SessionService } from '../../services/session.service'
import { UsuariosService } from '../../services/usuarios.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  requiredType="1"
  fecha:object={id:0, anio:'', trimestre:'', fechaInicial:'', fechaFinal:''};
  parametros:object= { id: 0, diasCambioContrasena: 0, diasBloqueoUsuario: 0, tiempoCerrarSesion: 0, numeroIntentosFallidos:0, diasAvisoBloqueo:0 };
  anios=[];
  helps:any={
    userAdmin:'Administración de usuarios: En esta sección podrá crear, editar y eliminar los usuarios de las entidades que tienen acceso al aplicativo.',
    legalAgents:'Representantes legales:  En esta sección podrá cargar un archivo plano con la información de los representantes legales de las entidades vigentes a la fecha. ',
    dateAdmin:'Administración de fechas: En esta sección podrá configurar el rango de fechas en el cual estará disponible el proceso de liquidación y consulta para las entidades. ',
    parameterAdmin:'Administración de parámetros: En esta sección podrá configurar los parámetros para el funcionamiento de la página web y actualización de usuarios. ',
    results:'Resultado proceso liquidación: En esta sección podrá consultar un reporte con los datos ingresados por las entidades en el aplicativo para calcular la prima del Seguro de Depósitos. '
  }
  sectores: any;
  IdSector:number=0;
  Anio:string='';
  Trimestre:string=''


  constructor (private usuariosService:UsuariosService, private sessionService:SessionService, private fechasService:FechasService,private parametrosService:ParametrosService){}
  ngOnInit(){
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    for(let i=2020; i<=2030; i++){
      this.anios.push(i)
    }
    this.getSectores()
    //console.log(this.anios)
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip({trigger:"hover"})

    }, 500);

  }
    inicio() {
        $("#borrar").prop('checked', false);
        $("#consultas").prop('checked', false);
        $("#borrado").attr('hidden', true);
        
    }
  getSectores(){
    this.usuariosService.getSectores().subscribe(
      response=> {this.sectores = response;},
      err=> {console.error(err)}
    )
  }

  getParametros(){
    this.parametrosService.getParametros().subscribe(
      response=> {
        this.parametros = response;
        //console.log(this.parametros)
      },
      err=> console.error(err)
    )
  }

  openReport(){
    if (this.Anio!= "")
    {
      if (this.Trimestre==""){
        this.Trimestre = "T0";
      }
      let reportUrl = `/reporte/${this.IdSector}/${this.Anio}/${this.Trimestre}`
      window.location.href = reportUrl;
    }
    else{
      Swal.fire({
        text:  "El año es obligatorio.",
        icon: 'info'
      });
    }

  }


}
