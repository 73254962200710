import { Component } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-norma',
  templateUrl: './norma.component.html',
})
export class NormaComponent {
  titulo = "Normatividad";
  requiredType="2";

  constructor(private sessionService:SessionService){}

  ngOnInit(){
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
  }

}
