import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
  titulo = "Contáctenos";
  requiredType="2";

  constructor(private sessionService:SessionService){}

  ngOnInit(){
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    $('[data-toggle="tooltip"]').tooltip('hide')

  }

}
