import { Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class GlobalsConstants {
    constructor(private appConfigService: AppConfigService) {}

    readonly appConfig:string = this.appConfigService.apiBaseUrl;
    //readonly  SERVER_address:string = 'http://192.168.22.46:8086/';   // Producción Fogafin
    readonly  SERVER_address:string = this.appConfig; // 'http://192.168.22.46:8088/';     // QA
    //readonly  SERVER_address:string = 'http://192.168.22.46:8087/';   // Desarrollo
	//readonly  SERVER_address:string = 'https://localhost:44396/';     // Localhost
    readonly API_address:string = this.SERVER_address+'Api/';
}
