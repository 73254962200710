import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { SessionService } from './session.service';
import{ GlobalsConstants } from '../commons/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {
  constructor(private http:HttpClient, private session:SessionService,private _constants:GlobalsConstants) { }
  API_URI = this._constants.API_address+"Parametros/"
  getParametros(){
    let header = this.session.getStorageSession();
    return this.http.get(`${this.API_URI}Cargar/`, {'headers':header})
  }

  updateParametros(parametros: any){
    let header = this.session.getStorageSession();
    return this.http.post(`${this.API_URI}Guardar/`,parametros, {'headers':header})
  }
}
