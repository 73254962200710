import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'nav-foot',
  templateUrl: './nav-foot.component.html',
  styleUrls: ['./nav-foot.component.css']
})
export class NavFootComponent implements OnInit {
  userType:any;
  ngOnInit(){
    this.userType = window.localStorage.getItem('userType');
    if(this.userType==1){
      $('.hiddeable').addClass('d-none')
    }
    $('[data-toggle="tooltip"]').tooltip('hide')

  }
}
