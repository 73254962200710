import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import { GlobalsConstants } from '../../commons/global-constants';

import Swal from 'sweetalert2';
declare var $: any;
@Component({
    selector: 'app-pagos-anuales',
    templateUrl: './pagos-anuales.component.html'
})

export class PagosAnualesComponent implements OnInit {
    titulo = "Pagos anuales de prima de SD";
    anio = this.activatedRoute.snapshot.params["anioPSD"];
    nombreEntidad = window.localStorage.getItem('nombreEntidad');
    idSector = window.localStorage.getItem('sectorEntidad');
    idEntidad = window.localStorage.getItem('idEntidad');

    email = window.localStorage.getItem('emailUsuario');
    nombreUsuario = window.localStorage.getItem('nombreUsuario');

    ReportClienteObj: any = {
        IdSector: Number(this.idSector),
        IdEntidad: Number(this.idEntidad),
        email: this.email,
        nombreUsuario:this.nombreUsuario,
    };

    datos: any;

    constructor(private activatedRoute: ActivatedRoute, private liquidacionService: LiquidacionService, private globals: GlobalsConstants) {}

    ngOnInit() {
        this.ReportClienteObj.Anio = this.anio;
        this.liquidacionService.getPDF(this.ReportClienteObj, this.nombreEntidad + "_0", this.email, this.nombreUsuario).subscribe(response => {
            this.datos = response; console.log(this.datos);
            if (response == '<div class="pagina1"></div>' || response == '') {
                Swal.fire({
                    html: `<b>No hay informaci&#243;n para el rango consultado`, icon: 'error'
                }).then((result) => {
                    if (result.isConfirmed) {
                        let reportUrl = `/Home`;
                        window.location.href = reportUrl;
                    }
                });
            }
        })
    }
    onSubmit() {
        $("#btn").addClass("d-none");
        $("#tectobtn").removeClass("d-none");

        //******* Genera el documento
        this.ReportClienteObj.Anio = this.anio;
        this.liquidacionService.getPDF(this.ReportClienteObj, this.nombreEntidad + "_1", this.email, this.nombreUsuario).subscribe(response => {
            if (response === '' ) {
                Swal.fire({
                    confirmButtonText: 'Cerrar', html: "<table style='font-size: 20px;'><tr><td style='font-size: 22px;font-weight:bold;'><b>Certificado de pagos por prima de SD</b></td></tr><tr><td><hr></td></tr><tr><td class='text-justify'>Su solicitud no se pudo radicar, consultar con el administrador. <br><br>Cualquier inquietud adicional la puede realizar al correo recaudos@fogafin.gov.co</td></tr></table>", preConfirm: (isConfirm) => {
                        $("#btn").removeClass("d-none");
                        $("#tectobtn").addClass("d-none");
                    }
                });
            }
            else {
                Swal.fire({
                    confirmButtonText: 'Cerrar', html: "<table style='font-size: 20px;'><tr><td style='font-size: 22px;font-weight:bold;'><b>Certificado de pagos por prima de SD</b></td></tr><tr><td><hr></td></tr><tr><td class='text-justify'>Su solicitud fue recibida con \u00e9xito. El n\u00famero de radicado es " + response + ". La certificaci\u00f3n ser\u00e1 enviada al correo " + this.email + " en un tiempo no mayor a diez d\u00edas h\u00e1biles. <br><br>Cualquier inquietud adicional la puede realizar al correo recaudos@fogafin.gov.co</td></tr></table>", preConfirm: (isConfirm) => {
                        window.location.href = "home"; // Si el usuario di� click en 's�', se retornar� true.
                    }
                });
            }
        })


    }
}
