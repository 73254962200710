import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LiquidacionService } from '../../services/liquidacion.service'
import Swal from 'sweetalert2';
import { SessionService } from '../../services/session.service'
import { identifierModuleUrl } from '@angular/compiler';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
    selector: 'app-calificacion',
    templateUrl: './calificacion.component.html',
})
export class CalificacionComponent implements OnInit {
    titulo = "Paso 2 - Calificación trimestral";
    requiredType = "2";
    step = 2;

    calificacionObj: any = { IdSector: 0, IdEntidad: 0, Anio: "", Trimestre: "" };
    nextStep = false;
    objMes1: any = [];
    objMes1Copy: any = [];
    objMes2: any = [];
    objMes3: any = [];
    monthName = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    objCalificacionTrimestre: any;
    coordinates: string[];
    periodo: string[];
    paso: any;
    nameMes1: string;
    nameMes2: string;
    nameMes3: string;
    depositostotalesMes1: number;
    depositostotalesMes2: number;
    depositostotalesMes3: number;
    namePaso: string;


    EISMes1: number;
    EISMes2: number;
    EISMes3: number;

    //********************************************
    categories = [
        { categoria: "", datos: [] },
        { categoria: "Capital", datos: [] },
        { categoria: "Activos", datos: [] },
        { categoria: "Gestión", datos: [] },
        { categoria: "Rentabilidad", datos: [] },
        { categoria: "Liquidez", datos: [] },
        { categoria: "Concentración", datos: [] },
        { categoria: " ", datos: [] }
    ];
    active: boolean = false;
    inactive: boolean = true;
    responseEstadoLiquidacion: any;

    resp: any = [];
    respTramiteData: any = [];

    constructor(private sessionService: SessionService, private liquidacionService: LiquidacionService, private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.paso = this.activatedRoute.snapshot.params.paso;
        this.sessionService.verifySession()
        this.sessionService.verifyRequired(this.requiredType)
        this.periodo = window.localStorage.getItem('periodo').split('-');
        this.coordinates = window.localStorage.getItem('coordinates').split(';');
        this.calificacionObj.IdSector = Number(this.coordinates[0]);
        this.calificacionObj.IdEntidad = Number(this.coordinates[1]);
        this.calificacionObj.Anio = this.periodo[1];
        this.calificacionObj.Trimestre = this.periodo[0];
        this.calificacionObj.paso = this.paso;

        if (this.calificacionObj.IdSector == '28') {
            this.router.navigate(['/home']);
        }


        this.liquidacionService.validarEstadoProceso(this.calificacionObj).subscribe(
            response => {
                this.resp = response;
                //if (this.resp.paso == 3) {/*Bloquea si el proceso va en paso 3 liquidacion ok*/
                if (this.resp.radicado != null) {/*Bloquea si OnBase devuelve numero de radiado ok*/
                    //if (this.resp.estado == 1) {/*Boblea si el estado del proceso es=1 cuando las calificaciones qdan ok y se pasa a estado 3*/
                    $('a.action').addClass('d-none');
                    this.active = true;
                    this.inactive = false;
                }
                else $('a.action').removeClass('d-none')
            },
            error => { console.error(error) }
        )
        /*
        this.liquidacionService.validarEstadoProceso(this.calificacionObj).subscribe(
            response => {
                setTimeout(() => {
                    this.responseEstadoLiquidacion = response;
                    let resp: any = false;
                    resp = this.responseEstadoLiquidacion.estado;
                    this.active = resp;
                    this.inactive = !this.active;
                    if (this.active) {
                        $('a.action').addClass('d-none')
                         $('input.action').setAttribute('disabled',true)
                    } else {
                        $('a.action').removeClass('d-none')
                    }

                }, 750);
            },
            error => {
                console.error(error)
            }
        )
        */
        this.getDataCalificacion()
        $('[data-toggle="tooltip"]').tooltip('hide')

        this.depositostotalesMes1 = 0;
        this.depositostotalesMes2 = 0;
        this.depositostotalesMes3 = 0;
        this.getDataBaseCobroTramiteData();
    }


    getDataBaseCobroTramiteData() {
        this.liquidacionService.getDataBaseCobroTramiteData(this.calificacionObj).subscribe(
            response => {
                this.respTramiteData = response;
            }
        )
    }
    filterItemsOfType(type) {

    }
    //************************************************************************************************ 
    getDataCalificacion() {
        this.liquidacionService.getCalificacion(this.calificacionObj).subscribe(
            response => {
                setTimeout(() => {

                    this.objCalificacionTrimestre = response;

                    this.nameMes1 = this.monthName[Number(this.objCalificacionTrimestre.meses[0].mes)]
                    this.nameMes2 = this.monthName[Number(this.objCalificacionTrimestre.meses[1].mes)]
                    this.nameMes3 = this.monthName[Number(this.objCalificacionTrimestre.meses[2].mes)]


                    //********************************************
                    this.categories.map(row => {
                        let datos = this.objCalificacionTrimestre.meses[0].cuentas.filter(category => category.categoria == row.categoria);
                        let position = this.categories.findIndex(category => category.categoria == row.categoria);
                        this.categories[position].datos.push(datos)
                    })

                    this.depositostotalesMes1 = this.categories.find(e => e.categoria == "").datos[0][0].valorFogafin * 1000;

                    this.categories.splice(0, 1);
                    this.objMes1 = this.categories;

                    this.categories = [
                        { categoria: "", datos: [] },
                        { categoria: "Capital", datos: [] },
                        { categoria: "Activos", datos: [] },
                        { categoria: "Gestión", datos: [] },
                        { categoria: "Rentabilidad", datos: [] },
                        { categoria: "Liquidez", datos: [] },
                        { categoria: "Concentración", datos: [] },
                        { categoria: " ", datos: [] }
                    ];
                    //********************************************
                    this.categories.map(row => {
                        let datos = this.objCalificacionTrimestre.meses[1].cuentas.filter(category => category.categoria == row.categoria);
                        let position = this.categories.findIndex(category => category.categoria == row.categoria);
                        this.categories[position].datos.push(datos)
                    })

                    this.depositostotalesMes2 = this.categories.find(e => e.categoria == "").datos[0][0].valorFogafin * 1000;
                    this.categories.splice(0, 1);
                    this.objMes2 = this.categories;

                    this.categories = [
                        { categoria: "", datos: [] },
                        { categoria: "Capital", datos: [] },
                        { categoria: "Activos", datos: [] },
                        { categoria: "Gestión", datos: [] },
                        { categoria: "Rentabilidad", datos: [] },
                        { categoria: "Liquidez", datos: [] },
                        { categoria: "Concentración", datos: [] },
                        { categoria: " ", datos: [] }
                    ];

                    //********************************************
                    this.categories.map(row => {
                        let datos = this.objCalificacionTrimestre.meses[2].cuentas.filter(category => category.categoria == row.categoria);
                        let position = this.categories.findIndex(category => category.categoria == row.categoria);
                        this.categories[position].datos.push(datos)
                    })

                    this.depositostotalesMes3 = this.categories.find(e => e.categoria == "").datos[0][0].valorFogafin * 1000;
                    this.categories.splice(0, 1);
                    this.objMes3 = this.categories;

                    //console.log(this.categories)
                    $('#sendComment').attr('disabled', !this.objCalificacionTrimestre.comtcompl)
                    setTimeout(() => {
                        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover', html: true })
                    }, 500);
                }, 750);
            },
            error => console.error(error)
        )

    }
    //******************************************************************************************************************************************** REVISAR
    getCommentStatus() {
        this.liquidacionService.getCalificacion(this.calificacionObj).subscribe(
            response => {
                let responseObj: any = response;
                $('#sendComment').attr('disabled', !responseObj.comtcompl)
            },
            error => console.error(error)
        )
    }

    formatValue(value) {
        return value.replace(/-\/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    validateCalificacion(cuenta, indice) {

        if (!this.active) {
            let mes = Number(this.objCalificacionTrimestre.meses[indice].mes)
            this.calificacionObj.mes = mes;
            this.calificacionObj.cuenta = cuenta;
            let posCuenta = this.objCalificacionTrimestre.meses[indice].cuentas.findIndex(row => row.id == cuenta)

            // console.log(this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta])

            let valorTemp = (<HTMLInputElement>document.getElementById(cuenta + "_" + indice)).value;
            this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].valor = Number(valorTemp);
            this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].estado = 1;

            this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].coincide = this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].valorFogafin == Number(this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].valor) ? true : false;
            this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].coincide ? this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].comentario = "" : null;

            // console.log(this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta])
        }
    }

    setComentario() {
        let cuenta = $('#mdl_comment').attr('data-cuenta');
        let indice = $('#mdl_comment').attr('data-indice');
        let posCuenta = this.objCalificacionTrimestre.meses[indice].cuentas.findIndex(row => row.id == cuenta)
        this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].comentario = $(`#mdl_comment`).val();
        $('#comentario').modal('hide');
    }

    saveCalificacion() {
        if (!this.active) {
            this.liquidacionService.saveDataCalificacion(this.objCalificacionTrimestre).subscribe(
                response => {
                    let objResponse: any = { estado: false };
                    objResponse = response;
                    if (objResponse.estado) {
                        Swal.fire({
                            text: "Datos guardados satisfactoriamente",
                            icon: 'success'
                        });
                        this.getCommentStatus()
                    } else {
                        Swal.fire({
                            text: "No se guardaron los datos",
                            icon: 'info'
                        });
                    }

                },
                error => console.error(error)
            )
        }
    }

    countByCategory(categoria, indice) {
        let number = this.objCalificacionTrimestre.meses[indice].cuentas.filter(row => row.categoria == categoria);
        return number.length
    }

    printCategoria(categoria) {
        if (!this.categories.some(category => category == categoria)) {
            this.categories.push(categoria);
            //console.log(this.categories)
            return '';
        } else {
            return 'd-none';
        }
    }

    validateQCategoria(categoria, indice) {
        let rows = this.objCalificacionTrimestre.meses[indice].cuentas.filter(row => row.categoria == categoria)
        return !rows.some(row => row.coincide == false);
    }

    showCommentInput(cuenta, indice) {
        let posCuenta = this.objCalificacionTrimestre.meses[indice].cuentas.findIndex(row => row.id == cuenta)
        let comentario = this.objCalificacionTrimestre.meses[indice].cuentas[posCuenta].comentario;
        $('#mdl_comment').val(comentario == "" ? "" : comentario)
        $('#mdl_comment').attr({ 'data-cuenta': cuenta, "data-indice": indice });
        $('#comentario').modal('show');
    }

    sendComment() {
        this.router.navigate(['/solicitudes/2']);
    }

    toFixedNumber(cuenta, indice) {
        let value = parseFloat($(`#${cuenta}_${indice}`).val()).toFixed(2)
        //console.log(value)
        $(`#${cuenta}_${indice}`).val(value)
    }

    Validate() {
        this.liquidacionService.validatePasos(this.calificacionObj).subscribe(
            response => {
                setTimeout(() => {
                    let objResponse: any = { estado: false };
                    objResponse = response;

                    if (!objResponse.estado) {
                        Swal.fire({
                            text: "Los datos ingresados en la base de cobro y la calificación mensual presentan diferencias respecto a los datos procesados por Fogafín. Por favor revise la información y de continuar la diferencia envíe sus comentarios a través de la opción Enviar comentarios",
                            icon: 'info'
                        });
                    } else {
                        this.router.navigate(['/liquidacion']);

                    };
                }, 500);
            },
            error => console.error(error)
        )
    }

    missingOneDecimalCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (x && x.length === 1) return true;
        return false;
    }

    missingAllDecimalsCheck(nStr) {
        nStr += '';
        const x = nStr.split(',')[1];
        if (!x) return true;
        return false;
    }

    formatoNumero(nStr) {
        let r: String;
        if (nStr === '') return '';
        if (Number.isNaN(nStr)) return '';
        let x, x1, x2, rgx, y1, y2;
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? ',' + x[1] : '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
        }

        /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
        if (x1.indexOf(',') !== -1) {
            y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

            y2 = x1.split(',');
            r = y2[0] + y1;
        } else {
            r = x1 + x2;
            if (this.missingOneDecimalCheck(r)) return r += '0';
            if (this.missingAllDecimalsCheck(r)) return r += ',00';
        }

        return r;
    }

}
