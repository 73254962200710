import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service'
import { SessionService } from '../../services/session.service'
declare var $: any;
@Component({
  selector: 'app-nav-menu-alt',
  templateUrl: './nav-menu-alt.component.html',
  styleUrls: ['./nav-menu-alt.component.css']
})
export class NavMenuAltComponent implements OnInit{
  tipoUsuario: any;
  nombreUsuario:string;
  constructor( private sessionService:SessionService, private router:Router, private loginService:LoginService){}
  @Input("title") title: string;

  ngOnInit(){
    this.getUserData()

  }

  getUserData(){
    // valido la fecha de último registro de inicio de sesión para evitar que se reabra el navegador con la sesión abierta.
    let fechaInicio = window.localStorage.getItem('fechaInicio');
    let fecha = new Date(window.localStorage.getItem('fechaInicio'));
    fecha.setHours(fecha.getHours() + 1);
    let ahora = new Date();

    if (fecha < ahora ){
      localStorage.clear()
       this.router.navigate(['']);
     }
    else
    {
      window.localStorage.setItem('fechaInicio', ahora.toString())
    }

    this.tipoUsuario =  window.localStorage.getItem('userType');
    let nombreUsuario =  window.localStorage.getItem('nombreUsuario');
    let nombreEntidad =  window.localStorage.getItem('nombreEntidad');
    if (this.title == "Paso 3 - Liquidación neta"){
      let requiredType="2"
      this.sessionService.verifySession()
      this.sessionService.verifyRequired(requiredType)
      let coordinates = this.sessionService.getCoordinatesSession().split(';');
      let idSectorV = Number(coordinates[0]);
      if (idSectorV == 28){
        this.title = "Paso 2 - Liquidación neta"
      }
    }

    //console.log(this.tipoUsuario)
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip('hide')

    }, 500);

    if(this.tipoUsuario=='1'){
      this.nombreUsuario = nombreUsuario
    }else{
      this.nombreUsuario = `${nombreUsuario} - ${nombreEntidad}`
    }
  }
  cerrarSesion(){
    let session={id:window.localStorage.getItem('sessionId')}
    this.loginService.logout(session).subscribe(
      response=> {
        //console.log(response)
      },
      err=>console.error(err)
    )
    localStorage.clear()
    this.router.navigate(['']);

  }

}
