    import { Component, OnInit, ɵConsole } from '@angular/core';
import { LiquidacionService } from '../../services/liquidacion.service'
import { SessionService } from '../../services/session.service'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

//import { saveAs } from "file-saver";

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  requiredType="2";
  processStatus:any;
  selfAssessmentStatus:any=true;
  helps:any={
    userManual:'Manual de usuario: En esta sección encontrará la guía o manual de uso del aplicativo. En él se detalla paso a paso como se debe realizar la liquidación.',
    faqs:'Preguntas Frecuentes: En esta sección encontrará un listado con respuestas de ayuda e interés general.',
    normativity:'Normatividad: En esta sección encontrará las resoluciones y circulares vigentes que reglamentan la prima del Seguro de Depósitos. ',
    contactUs:'Contáctenos: En esta sección encontrará los datos de contacto de los departamentos encargados del proceso de cálculo y recaudo de la prima del Seguro de Depósitos. ',
    continueSelfAssement:'Continuar liquidación: En esta sección puede continuar el proceso de liquidación de la prima del Seguro de Depósitos. ',
    startSelfAssement:'Iniciar liquidación: En esta sección puede realizar el proceso de liquidación de la prima del Seguro de Depósitos. ',
    disableSelfAssement: 'Iniciar liquidación: La opción liquidación todavía no está disponible. Comuníquese con el departamento de Seguro de Depósitos para más información. ',
    ModConsultas:'Consultas: Puede consultar el histórico de las liquidaciones y descargar la certificación de pago de la prima de Seguro de Depósitos. ',
    process:''
  }
  
  constructor( private liquidacionService:LiquidacionService,private sessionService:SessionService, private router:Router){}

    async ngOnInit() {
    this.sessionService.verifySession();
    this.sessionService.verifyRequired(this.requiredType);
    //this.processStatus = this.liquidacionService.verifyStatusProcess();

    this.liquidacionService.verifySelfAssessment().subscribe(
      response=>{
        this.selfAssessmentStatus=response;
        window.localStorage.setItem('periodo',`${this.selfAssessmentStatus.trimestre}-${this.selfAssessmentStatus.anio}`)
        
    if (this.selfAssessmentStatus.estado){
    this.liquidacionService.verifyStatusProcess().subscribe(
      response=>{
        this.processStatus=response;
        if (this.processStatus.estado){
          $('#SelfAssessmentText').html('Continuar liquidación');
          $('#SelfAssessmentIcon').addClass('fa-forward').removeClass('fa-power-off');
          $('#liqProcess').attr('title',this.helps.continueSelfAssement).tooltip('dispose').tooltip({ title: this.helps.continueSelfAssement});
        }else{
          $('#SelfAssessmentText').html('Iniciar liquidación');
          $('#SelfAssessmentIcon').addClass('fa-power-off').removeClass('fa-forward');
          $('#liqProcess').attr('title',this.helps.startSelfAssement).tooltip('dispose').tooltip({ title: this.helps.startSelfAssement});
        }
        $('[data-toggle="tooltip"]').tooltip()
      },error=>{console.error(error);}
    )
    }else{
          $('#SelfAssessmentText').html('Iniciar liquidación');
          $('#SelfAssessmentIcon').addClass('fa-power-off').removeClass('fa-forward');
          $('#liqProcess').attr('title',this.helps.startSelfAssement).tooltip('dispose').tooltip({ title: this.helps.startSelfAssement});
    }
      },error=>{console.error(error);}
    )
    $('[data-toggle="tooltip"]').tooltip('hide')
  }

  async getSelfAssessmentStatus(){
    if (this.selfAssessmentStatus.estado){
      $('.tooltip').hide()
      this.router.navigate(['/inicioliquidacion']);
    }else{
      Swal.fire({
        text:  "La opción liquidación todavía no está disponible. Comuníquese con el departamento de Seguro de Depósitos para más información",
        icon: 'info'
      });
    } 
  } 
  
}
