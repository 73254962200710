import { Component, OnInit, Input } from '@angular/core';
import { UsuariosService } from '../../services/usuarios.service';
import { SessionService } from '../../services/session.service';
import { LiquidacionService } from '../../services/liquidacion.service'
import { GlobalsConstants } from '../../commons/global-constants';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
})

export class ReporteComponent implements OnInit {
  titulo = "Resultado proceso de liquidación";
  requiredType='1'
  objReport:any={
    IdSector:0,
    Anio:'',
    Trimestre:''
  }
  fileExportLink:any;
  report:any=[];

  constructor (private liquidacionService:LiquidacionService,private activatedRoute:ActivatedRoute,private usuariosService:UsuariosService,private sessionService:SessionService, private globals:GlobalsConstants){}

    ngOnInit() {

        this.objReport.IdSector = this.activatedRoute.snapshot.params.sector!=''||this.activatedRoute.snapshot.params.sector!=null?Number(this.activatedRoute.snapshot.params.sector):0;
        this.objReport.Anio = this.activatedRoute.snapshot.params.anio;
        this.objReport.Trimestre = this.activatedRoute.snapshot.params.trimestre;
        this.getReport();
        this.sessionService.verifySession()
        this.sessionService.verifyRequired(this.requiredType)
    }

  getReport(){
    this.liquidacionService.getReport(this.objReport).subscribe(
      response=> {
       this.report = response
      },
      err=> {
        console.error(err)
      }
    )
  }

  fileExportar(){
    this.liquidacionService.fileExportar(this.objReport).subscribe(
      response=> {
        setTimeout(() => {
          console.log(response);
          let status:any={estado:false,detalle:""}
          status = response;
          let fileAddress = this.globals.SERVER_address + status.detalle;
            console.log(fileAddress);
          window.open(fileAddress, '_blank');
        }, 1500);
      },
      err=> {
        console.error(err)
      }
    )
  }
}
