import { Component, OnInit } from '@angular/core';
import { LiquidacionService } from 'src/app/services/liquidacion.service';
import { SessionService } from '../../services/session.service'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-autoliquidacion',
  templateUrl: './autoliquidacion.component.html',
})
export class AutoliquidacionComponent implements OnInit {
  titulo = "Liquidación";
  requiredType="2"
  btnProcess:string="";
  processStatus:any={estado:true,paso:0};
  selfAssessmentStatus:any=true;
  entity:string;
  

  constructor( private sessionService:SessionService,private liquidacionService:LiquidacionService,private router:Router){}

  ngOnInit(){
        
    this.entity = window.localStorage.getItem('coordinates')!=null? window.localStorage.getItem('coordinates').split(';')[0]:'';
    this.sessionService.verifySession()
    this.sessionService.verifyRequired(this.requiredType)
    this.liquidacionService.verifyStatusProcess().subscribe(
      response=>{
        this.processStatus=response;
      },error=>{
        console.error(error);
      }
    )
    
    setTimeout(() => {
    if(this.entity==''){
      Swal.fire({
        text:  `La información de la entidad no se encuentra disponible temporalmente. Vuelva mas tarde`,
        icon: 'info'
      });
      $('#btnValidacion').attr('disabled',true)
    }else{
      $('#btnValidacion').removeAttr('disabled')
      if(this.processStatus.estado){
        $('#startedProcess').removeClass('d-none');
        this.btnProcess = 'Continuar proceso de liquidación';
      }else{
        if(this.entity=='28'){
          $('#sedpes').removeClass('d-none');
        }else{
          $('#noSedpes').removeClass('d-none');
        }
        this.btnProcess = 'Iniciar validación de la información';
      }
      
    }
    $('[data-toggle="tooltip"]').tooltip('dispose')
  }, 800);
  } 

  gotoProcess(){
    if(this.processStatus.estado){
      this.liquidacionService.goToStep(1);
    }else{
      this.router.navigate(['/validacionreglamento']);
    }

  }
}
