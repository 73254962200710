import { Component, Input, ViewChild, OnInit, ElementRef } from '@angular/core';
import { LoginService } from '../../services/login.service'
import { SessionService } from '../../services/session.service'
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit {

    recaptcha: string = '';
    @Input() credenciales: any = {
        email: '',
        password: '',
        sessionId: ''
    };

    loginContent: any = {
        id: "",
        fecha: "",
        idUsuario: 0,
        nombreUsuario: "",
        emailUsuario: "",
        idSectorUsuario: 0,
        idEntidadUsuario: 0,
        usuarioAdmin: Boolean,
        estado: Boolean,
        resultado: 0
    }

    session = window.localStorage.getItem('sessionId');

    ngOnInit() {
        this.sessionService.verifySession()
        if (this.session != null) {
            this.sessionService.goOwnHome();
        }
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    constructor(private loginService: LoginService, private router: Router, private sessionService: SessionService) { }

    resolved(captchaResponse: string) {
        this.recaptcha = captchaResponse;
        setTimeout(() => {
            grecaptcha.reset(1);
            this.recaptcha = '';
        }, 40000)
        //console.log(`Resolved captcha with response: ${captchaResponse}`);
    }

    onSubmit(credenciales) {
        //console.log(credenciales);
        if (this.recaptcha != '') {
            $('#login').attr('disabled', true);
            $('#icon').addClass('d-none');
            $('#loading').removeClass('d-none');

            this.loginService.makeLogin(credenciales).subscribe(
                response => {
                    this.loginContent = response;
                    if (this.loginContent.resultado == 1) {
                        if (this.loginContent.id != null) {
                            let fecha = new Date();
                            let type = this.loginContent.usuarioAdmin == true ? "1" : "2";
                            window.localStorage.setItem('sessionId', this.loginContent.id);
                            window.localStorage.setItem('nombreUsuario', this.loginContent.nombreUsuario);
                            window.localStorage.setItem('emailUsuario', this.loginContent.emailUsuario);
                            window.localStorage.setItem('nombreEntidad', this.loginContent.nombreEntidad);
                            window.localStorage.setItem('sectorEntidad', this.loginContent.idSectorUsuario);
                            window.localStorage.setItem('idEntidad', this.loginContent.idEntidadUsuario);
                            window.localStorage.setItem('userType', type);
                            window.localStorage.setItem('sessionTime', '1');
                            window.localStorage.setItem('fechaInicio', fecha.toString());
                            window.localStorage.setItem('coordinates', `${this.loginContent.idSectorUsuario};${this.loginContent.idEntidadUsuario}`);
                            if (this.loginContent.restablecer == 1) {
                                window.localStorage.clear();
                                window.localStorage.setItem('emailUsuario', this.loginContent.emailUsuario);
                                this.router.navigate([`/cambiarContrasena/${this.loginContent.token}`]);

                            }
                            else {
                                this.loginService.setSessionTime();
                                this.router.navigate(['/inicio']);
                            }
                        }
                    }
                    else if (this.loginContent.resultado == 2) {
                        Swal.fire({text: 'Usuario Bloqueado!', icon: 'error'});
                        $('#login').removeAttr('disabled', true);
                        $('#icon').removeClass('d-none');
                        $('#loading').addClass('d-none');

                    }
                    else if (this.loginContent.resultado == 3) {
                        Swal.fire({
                            text: `En el momento se encuentra el usuario ${this.loginContent.nombreUsuario} registrado dentro de la aplicación. Si requiere ingresar, el usuario registrado debe cerrar la sesión.`,
                            icon: 'info'
                        });
                        $('#login').removeAttr('disabled', true);
                        $('#icon').removeClass('d-none');
                        $('#loading').addClass('d-none');
                    }
                    else if (this.loginContent.resultado == 4) {
                        Swal.fire({text: `Usuario no activo.`, icon: 'error'});
                        $('#login').removeAttr('disabled', true);
                        $('#icon').removeClass('d-none');
                        $('#loading').addClass('d-none');
                    }
                    else {
                        Swal.fire({text: 'Credenciales inválidas',icon: 'error'});
                        $('#login').removeAttr('disabled', true);
                        $('#icon').removeClass('d-none');
                        $('#loading').addClass('d-none');
                    }
                },
                error => {
                    console.error(error);
                    Swal.fire({text: 'Error al establecer conexión con el servidor!', icon: 'error'});
                    $('#login').removeAttr('disabled', true);
                    $('#icon').removeClass('d-none');
                    $('#loading').addClass('d-none');
                }
            )
        }
        else {
            Swal.fire({ text: 'Por favor active recapcha para continuar.', icon: 'info' });
        }
    }
    errored() {
        console.log(`Resolved response token: TimeOut`);

    }
}