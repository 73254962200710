import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalsConstants } from '../commons/global-constants';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  constructor(private http:HttpClient, private router:Router,private _constants:GlobalsConstants,private loginService:LoginService) { }
  API_URI = this._constants.API_address+"Usuarios"

  getCoordinatesSession(){
    let coordinates = window.localStorage.getItem('coordinates')
    return coordinates;
  }

  getStorageSession(){
    let sessionId = window.localStorage.getItem('sessionId')
    let headers = new HttpHeaders().set('sessionId', sessionId).set('Access-Control-Allow-Origin','*')
    return headers;
  }

  validateSession(){ 

    let sessionId = window.localStorage.getItem('sessionId')  
    return this.http.post(`${this.API_URI}/ValidarSesion`,sessionId)
  }

  verifySession(){
    let getTime = window.localStorage.getItem('sessionTime')
    let remaining = Number(getTime);
    let fecha = new Date(window.localStorage.getItem('fechaInicio'));
    fecha.setMinutes(fecha.getMinutes() + remaining + 1);
    let ahora = new Date();
    if (fecha < ahora ){
      localStorage.clear()
      this.router.navigate(['']);
    }
    else
    {
      var sessionResp:any = {
        "tipo": 0,
        "estado": false
      }
      let session = {id:window.localStorage.getItem('sessionId')} ;
      this.http.post(`${this.API_URI}/ValidarSesion`,session).subscribe(
        response=>{
          sessionResp = response
          if (sessionResp.estado){
            window.localStorage.setItem('sessionTime',sessionResp.sedr)
            let ahora = new Date();
            window.localStorage.setItem('fechaInicio', ahora.toString())
          } 
      
          if (!sessionResp.estado){
            window.localStorage.clear()
            this.router.navigate(['']);
          } 
        },err=>{
          sessionResp.estado= false
          window.localStorage.clear()
          this.router.navigate(['']);
        }
      
      )
    }
  }

  verifyRequired(type){
    let userType = window.localStorage.getItem('userType');
    if (userType==type){
      //console.log(userType,type)
    }else{
      //console.log('Redirigido')
      this.router.navigate(['/inicio']);
    }
  }
  
  goOwnHome(){
    let userType = window.localStorage.getItem('userType');
    if (userType=='1'){
      this.router.navigate(['/administracion']);
    }else{
      this.router.navigate(['/home']);
    }
  }

}
