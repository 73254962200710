import { Component, OnInit, Input } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { UsuariosService } from '../../services/usuarios.service';
import { SessionService } from '../../services/session.service'
import { GlobalsConstants } from '../../commons/global-constants';
import Swal from 'sweetalert2';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent implements OnInit {
  titulo = "Administración de usuarios";
  requiredType='1'
  usuarios : any = [];
  usuario: any={
    id: 0,
    idSector:0,
    idEntidad:0,
    nombreContacto:'',
    email:'',
    telefono:'',
    celular:'',
    password:'',
    activo:false,
    bloqueado:false,
    administrador:false,
    consulta:false,
    restablecer:false,
  };
  pages:any=0;
  objPages=[];
  page:any=1;
  selected : any = 0;
  @Input() buscar:any="";
  sectores:any;
  entidades:any;
  sectoresTemp:any;
  entidadesTemp:any;
  filterEntidades:any;
  objusuario:object ={};
  listEntidades=[];
  listSectores=[];
  fSector:string="";
  fEntidad:string="";
  fActivo:string="";
  fBloqueado:string="";
  fAdministrador:string="";
  fileExportLink:string='';
  passwordchg: string='';
  filterObj:any={
    sector:'',
    entidad:'',
    activo:'',
    bloqueado:'',
    administrador:''
  }
  filterName:any;

  constructor (private usuariosService:UsuariosService,private sessionService:SessionService, private globals:GlobalsConstants){}
  ngOnInit(){
    this.setSelected(0);
    this.getUsuarios();
    this.getSectores();
    this.sessionService.verifySession()
   this.sessionService.verifyRequired(this.requiredType)
   this.usuariosService.getSectores().subscribe(
     response=> {
       this.sectoresTemp = response;
     },
     err=> {
       console.error(err)
     }
   )
  }
  clearObjUsuario(){
    $('#emailMessaje').addClass('d-none')
    this.objusuario={
      id: 0,
      idSector:'',
      nombreSector:'',
      idEntidad:'',
      nombreEntidad:'',
      nombreContacto:'',
      email:'',
      telefono:'',
      celular:'',
      password:'',
      activo:false,
      bloqueado:false,
      administrador:false,
      consulta:false,
      restablecer:false,
    };
  }

  getUsuarioFilter(){
    let filter='';
    $('.filter').click(function() {
      filter = $(this).val();
      $('.filter').not(this).val('');
    });
    console.log(filter)
    this.usuariosService.getUsuariosFilter(filter).subscribe(
      response=> {
        this.usuarios = response;
        this.usuarios.forEach(row => {
          row.idSector = row.idSector==0?'':row.idSector;
        })
        console.log(this.sectores)
      },
      err=> {
        console.error(err)
      }
    )
  }

  showFilter(filter:any){
    this.filterName = filter;
    $('#filterSelector').modal('show')
  }

  setFilter(filter:any){
    this.usuarios=[];
    this.page = 1;
    this.search()
    if (filter=='sector')
    {
      this.getEntidades();
    }
  }

  clearFilter(filter){
       switch (filter) {
      case 'sector':
        this.filterObj.sector = '';
        break;
      case 'entidad':
        this.filterObj.entidad = '';
        break;
      case 'activo':
        this.filterObj.activo = '';
        break;
      case 'bloqueado':
        this.filterObj.bloqueado = '';
        break;
      case 'administrador':
        this.filterObj.administrador = '';
        break;
    }
    this.page = 1;
    this.search()
    this.getEntidades();
    let filterValues = Object.values(this.filterObj)
    console.log(filterValues)
    let filterIsSet = filterValues.some(filter =>filter!='');
    console.log(filterIsSet)

    if(filterIsSet){
      $("#clear").removeClass('d-none')
    }else{
      $("#clear").addClass('d-none')
    }
    $('#filterSelector').modal('hide')
  }

  getUsuarios(){
    this.usuariosService.getUsuarios(this.page.toString()).subscribe(
      response=> {
        let data:any=response
        this.usuarios = data.usuarios;
        this.pages=data.paginas;
        this.page=data.pagina;
        this.usuarios.forEach(row => {
          row.idSector = row.idSector==0?'':row.idSector;
          !this.listEntidades.some(resp=>resp.id==row.idEntidad&&(resp.id!=''||resp.id!=null))?this.listEntidades.push({id:row.idEntidad,nombre:row.nombreEntidad}):null;
          this.listEntidades=this.listEntidades.filter(entidad=>entidad!=""&&entidad!=null)
        });
        this.objPages=[]
        for(let i=0; i<this.pages;i++){
          this.objPages.push(i+1)
        }
      },
      err=> {
        console.error(err)

      }
    )
    this.getEntidades();
  }

  changePage(page){
    this.page=page
    //console.log(this.page)
    this.search()
  }

  getSectores(){
    this.usuariosService.getSectores().subscribe(
      response=> {
        this.sectores = response;
        console.log(this.sectores)
        this.getEntidades();
      },
      err=> {
        console.error(err)
      }
    )
  }

  getEntidades(){
    let idSectorConsulta:String;
    if(this.filterObj.sector == '')
    {
      idSectorConsulta = '999';
    }
    else
    {
      idSectorConsulta = this.filterObj.sector;
    }
    this.usuariosService.getEntidades(idSectorConsulta, 'true').subscribe(
      response=>{
        this.entidades = response;
        this.listEntidades=this.entidades
      },
      err=>console.error(err)
    )
  }

  search(){
    this.usuariosService.searchUsuario(this.buscar,this.page.toString(),this.filterObj).subscribe(
      response=> {
        let data:any=response
        this.pages=data.paginas;
        this.page=data.pagina;
        this.usuarios = data.usuarios;
        $('#search').val()!=""?$("#clear").removeClass('d-none'):null;
        this.objPages=[]
        for(let i=0; i<this.pages;i++){
          this.objPages.push(i+1)
        }
      },
      err=> console.error(err)
    )
  }

  fileExportar(){
    let exportObject:any={type:'',search:''}
    exportObject.type = $("#fileType").val();
    exportObject.search = $('#search').val();
    this.usuariosService.fileExportar(exportObject).subscribe(
      response=> {
        //console.log(response)
        this.fileExportLink= `${this.globals.SERVER_address}${response}`
        window.open(this.fileExportLink, '_blank');
      },
      err=> console.error(err)
    )
  }


  clear(){
    $("#search").val('')
    this.getUsuarios();
    $("#clear").addClass('d-none')
    this.filterObj={
      sector:'',
      entidad:'',
      activo:'',
      bloqueado:'',
      administrador:''
    }
  }

  setPage(page){
    this.page = page
  }

  setSelected(id: number){
    $('.check').click(function() {
      $('.check').not(this).prop('checked', false);
    });

    if ($('.check:checked').length>0&&id!=0){
      this.selected = id;
      this.usuariosService.getUsuario(this.selected).subscribe(
        response=> {
          this.usuario = response
          this.usuario.password = '';
          this.usuariosService.getSectores().subscribe(
            response=> {
              this.sectoresTemp = response;
            },
            err=> {
              console.error(err)
            }
          )
          this.usuariosService.getEntidades(this.usuario.idSector, 'false').subscribe(
            response=>{
              this.entidadesTemp = response;
            },
            err=>console.error(err)
          )
        },
        err=> console.error(err)
      )
      $('#editar').removeAttr('disabled')
      $('#cancelar').removeAttr('disabled').removeClass('d-none')
      $('#eliminar').removeAttr('disabled').removeClass('d-none')

    }else{
      $('#editar').attr('disabled',true)
      $('#cancelar').attr('disabled',true).addClass('d-none')
      $('#eliminar').attr('disabled',true).addClass('d-none')

    }
  }

  changePassword(id: number){
    this.usuariosService.getPassword().subscribe(
      response=>{
        let password:any={password:''};
        password = response
        this.passwordchg = password.password;
      },
      err=>console.error(err)
    )
    let usuario : any={
      id: 0,
      idSector:0,
      idEntidad:0,
      nombreContacto:'',
      email:'',
      telefono:'',
      celular:'',
      password:'',
      activo:false,
      bloqueado:false,
      administrador:false,
      consulta:false,
      restablecer:false,
    };
    Swal.fire({
      html: '<b>Confirmar cambio de contraseña</b><br>Desea Cambiar la contraseña al usuario seleccionado?',
      icon: 'info',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:'Cambiar contraseña',
      cancelButtonText:'Cancelar',

    }).then((result) => {
      if (result.isConfirmed) {
        this.usuariosService.getUsuario(id).subscribe(
          response=> {
            usuario = response

            usuario.restablecer=true;
            usuario.password= this.passwordchg;
            this.usuariosService.updateUsuario(usuario).subscribe(
              response=>{
                //console.log(response)
                if (response[0]==1){
                  Swal.fire({
                    text: 'Se cambio la contraseña satisfactoriamente',
                    icon: 'success'
                  });
                }else{
                  Swal.fire({
                    text: 'No se pudo cambiar la contraseña',
                    icon: 'error'
                  });
                }
              },
              error=>{
                console.error(error)
                Swal.fire({
                  text: error[1],
                  icon: 'error'
                });
              }
            )
          },
          err=> console.error(err)
        )      }
    });

  }

  deleteUser(){
    let id = $('.check:checked').val();
    console.log(id)
    if ($('.check:checked').length>0&&id!=0){
      this.selected = id;
      Swal.fire({
        title: 'Eliminar usuario?',
        text: "Esta seguro de eliminar el usuario seleccionado?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#ccc',
        confirmButtonText: 'Eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.usuariosService.deleteUsuario(this.selected).subscribe(
            response=> {
              let objResponse:any =[];
              objResponse = response;
              if (objResponse[0]==1){
                Swal.fire({
                  html:  `<b>Usuario Eliminado!</b><br>${objResponse[1]}`,
                  icon: 'success'
                });
              }else{
                Swal.fire({
                  text:  objResponse[1],
                  icon: 'error'
                });

              }
            },
            err=> {
              console.error(err)
              Swal.fire({
                html: '<b>No se elimino el usuario</b><br>No se puedo establecer comunicación con el servidor',
                icon: 'error'
              });

            }
          )
        }
      })
    }
  }

  clearSelected(){
    $('.check').prop('checked', false);
    this.selected = 0;
    $('#editar').attr('disabled',true)
    $('#cancelar').attr('disabled',true).addClass('d-none')
    $('#eliminar').attr('disabled',true).addClass('d-none')
  }
}
